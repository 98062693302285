import { lazy } from 'react';

export default [
  {
    title: 'Call Back',
    component: lazy(() => import('./TopCallBack')),
    url: '/top/phone/callback',
    heading: 'Last Period',
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: 'Call Back',
    component: lazy(() => import('./AllCallBack')),
    url: '/phone/callback',
    heading: 'Last Period',
    requirePermission: true,
    roles: ['Super_Admin', 'Admin', 'Client'],
  },
  {
    title: 'Call Back',
    component: lazy(() => import('./AllCallBack')),
    url: '/phone/callback/:questionid',
    heading: 'Last Period',
    requirePermission: true,
    roles: ['Super_Admin', 'Admin', 'Client'],
  },
  {
    title: 'Call Back',
    component: lazy(() => import('./VasCallBack')),
    url: '/vas/phone/callback',
    heading: 'Last Period',
    requirePermission: true,
    roles: ['Super_Admin', 'Admin', 'Client'],
  },
  {
    title: 'Call Back',
    component: lazy(() => import('./LarcCallBack')),
    url: '/larc/phone/callback',
    heading: 'Last Period',
    requirePermission: true,
    roles: ['Super_Admin', 'Admin', 'Client'],
  },
  {
    title: 'Book Call Back',
    component: lazy(() => import('./BookCallBack')),
    url: '/phone/bookcallback',
    heading: 'Last Period',
    requirePermission: true,
    roles: ['Super_Admin', 'Admin', 'Client'],
  },
  {
    title: 'Appointment CallBack',
    component: lazy(() => import('./AppointmentCallBack')),
    url: '/top/phone/appointmentcallback',
    heading: 'Last Period',
    requirePermission: true,
    roles: ['Super_Admin', 'Admin', 'Client'],
  },
  {
    title: 'PhoneModule',
    component: lazy(() => import('./AppointmentCallBack')),
    url: '/top/phone/appointmentcallback/:id/:shareId',
    requirePermission: true,
    roles: ['Super_Admin', 'Admin', 'Client'],
  },
  {
    title: 'LARC Appointment CallBack',
    component: lazy(() => import('./AppointmentLarcCallBack')),
    url: '/larc/appointment/appointmentcallback',
    requirePermission: true,
    roles: ['Super_Admin', 'Admin', 'Client'],
  },
  {
    title: "LARC Appointment booked CallBack",
    component: lazy(() => import("./BookingLarcCallBack")),
    url: "/larc/appointment-booked/appointmentcallback",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Vas Appointment Callback",
    component: lazy(() => import("./AppointmentVasCallBack")),
    url: "/vas/appointment/appointmentcallback",
    requirePermission: true,
    roles: ['Super_Admin', 'Admin', 'Client'],
  },
  {
    title: 'Vas Appointment Booked Callback',
    component: lazy(() => import('./AppointmentBookedVasCallBack')),
    url: '/vas/appointment-booked/appointmentcallback',
    requirePermission: true,
    roles: ['Super_Admin', 'Admin', 'Client'],
  },
  {
    title: 'Counselling Appointment Callback',
    component: lazy(() => import('./VasCounsellingCallBack')),
    url: '/counselling/appointment/appointmentcallback',
    requirePermission: true,
    roles: ['Super_Admin', 'Admin', 'Client'],
  },
  {
    title: "Top appointment callback",
    component: lazy(() => import("./TopAppointmentCallBack")),
    url: "/top/appointment/callback/",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Top Book appointment callback",
    component: lazy(() => import("./TopAppointmentBookedCallBack")),
    url: "/top/appointment-booked/appointment/callback",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Top Book appointment callback",
    component: lazy(() => import("./TopCounsellingCallBack")),
    url: "/top/counselling-booked/appointment/callback",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Top Book appointment callback",
    component: lazy(() => import("./TopAbortionCallBack")),
    url: "/top/abortion-booked/appointment/callback",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  }
];
