import { lazy } from 'react';

export default [
  {
    title: 'Thank You',
    component: lazy(() => import('./thank_you')),
    url: '/vas/thank-you',
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: 'Book Appointment',
    component: lazy(() => import('./book_appointment')),
    url: '/vas/appointment/:id/:shareId',
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: 'Booking Confirmed',
    component: lazy(() => import('./booking_confirmed')),
    url: '/vas/booking-confirmed/:id/:shareId',    
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
];
