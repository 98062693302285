import { lazy } from "react";

export default [
  {
    title: "Email Reset",
    component: lazy(() => import("./EmailReset")),
    url: "/reset/emailreset",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "User"],
  },
  {
    title: "Mobile Reset",
    component: lazy(() => import("./MobileReset")),
    url: "/reset/mobilereset",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "User"],
  },
  {
    title: "Trouble Reset",
    component: lazy(() => import("./TroubleReset")),
    url: "/reset/troublereset",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "User"],
  },
  {
    title: "Email",
    component: lazy(() => import("./Email")),
    url: "/reset/email",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "User"],
  },
  {
    title: "Mobile",
    component: lazy(() => import("./Mobile")),
    url: "/reset/mobile",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "User"],
  },
  {
    title: "Check Message",
    component: lazy(() => import("./CheckMessage")),
    url: "/reset/checkmessage",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "User"],
  },
  {
    title: "Check Email",
    component: lazy(() => import("./CheckEmail")),
    url: "/reset/checkemail",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "User"],
  },
  {
    title: "Password Reset",
    component: lazy(() => import("./PasswordReset")),
    url: "/reset/passwordreset/:token/:close",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "User"],
  },
  {
    title: "Password Reset",
    component: lazy(() => import("./PasswordReset")),
    url: "/reset/passwordreset/:token",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "User"],
  },
  {
    title: "Password Changed",
    component: lazy(() => import("./PasswordChanged")),
    url: "/reset/passwordchanged",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "User"],
  },
  {
    title: "Memorable Word Changed",
    component: lazy(() => import("./SecurityPINChanged")),
    url: "/reset/memorablewordchanged",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "User"],
  },
  {
    title: "Verification Code",
    component: lazy(() => import("./VerificationCode")),
    url: "/reset/verificationcode",
    requirePermission: false,
  },
  {
    title: "SecurityPIN",
    component: lazy(() => import("./SecurityPIN")),
    url: "/reset/securitypin/:token",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "User"],
  },
];
