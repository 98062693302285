import { lazy } from "react";

export default [
  {
    title: "Landing page",
    component: lazy(() => import("./Landing")),
    url: "/admin/landing",
    heading: "Home screen",
    requirePermission: true,
    roles: ["Super_Admin", "Admin"],
  },
  {
    title: "Update page",
    component: lazy(() => import("./Update")),
    url: "/admin/upload-csv",
    requirePermission: true,
    roles: ["Super_Admin", "Admin"],
  },
  {
    title: "Update page",
    component: lazy(() => import("./Update")),
    url: "/admin/trigger-words",
    requirePermission: true,
    roles: ["Super_Admin", "Admin"],
  },
  {
    title: "Update page",
    component: lazy(() => import("./Update")),
    url: "/admin/permissions",
    requirePermission: true,
    roles: ["Super_Admin", "Admin"],
  },
  {
    title: "Update page",
    component: lazy(() => import("./Update")),
    url: "/admin/callback",
    requirePermission: true,
    roles: ["Super_Admin", "Admin"],
  },
  {
    title: "Update page",
    component: lazy(() => import("./Update")),
    url: "/admin/clientdashboard",
    requirePermission: true,
    roles: ["Super_Admin", "Admin"],
  },
  {
    title: "Update page",
    component: lazy(() => import("./Update")),
    url: "/admin/clientdashboard/:service",
    requirePermission: true,
    roles: ["Super_Admin", "Admin"],
  },
  {
    title: "Msi Staff",
    component: lazy(() => import("./MsiStaff")),
    url: "/admin/dashboard/msistaff",
    requirePermission: true,
    roles: ["Super_Admin", "Admin"],
  },
  {
    title: "Waitlist",
    component: lazy(() =>
      import("./Appointment/WaitList").then(({ WaitListPage }) => ({
        default: WaitListPage,
      }))
    ),
    url: "/admin/appointment/waitlist",
    requirePermission: true,
    roles: ["Super_Admin", "Admin"],
  },
  {
    title: "Clinic Info",
    component: lazy(() => import("./Appointment/ClinicInfo")),
    url: "/admin/appointment/clinic_information",
    requirePermission: true,
    roles: ["Super_Admin", "Admin"],
  },
];
