import React from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { staffLogin } from "../../services/auth";
import { useNavigate } from "react-router-dom";
import { setLocalStorage } from "../../utils/localstorage";

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const Azure_redirect = () => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const request = { ...loginRequest, account: accounts[0] };
  if (accounts.length > 0)
    instance.acquireTokenSilent(request).then((response: any) => {
      if (response.accessToken) {
        azureLogin(response.accessToken);
        response.accessToken = "";
      }
    });
  async function azureLogin(accessToken: any) {
    const staffdata = await staffLogin({
      externalToken: accessToken,
    });
    const data = staffdata.data;
    if (data) {
      setLocalStorage("userDetails", JSON.stringify(data));
      navigate("/Staff/AdminDashboard", { replace: true });
    }
  }

  const isAuthenticated = useIsAuthenticated();
  return (
    <>
      <h5>{isAuthenticated && <span> Redirecting....</span>}</h5>
      <br />
      <br />
    </>
  );
};

export default Azure_redirect;
