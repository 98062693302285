import { lazy } from "react";

export default [
  {
    title: "Check Eligibility",
    component: lazy(() => import("./CheckEligibility")),
    url: "/top/eligible-for-an-appointment",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Signup",
    component: lazy(() => import("./Signup")),
    url: "/top/signup",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Pregnancy",
    component: lazy(() => import("./Pregnancy")),
    url: "/top/eligibility",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Pregnancy",
    component: lazy(() => import("./Pregnancy")),
    url: "/top/eligibility/:id/:shareId",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Date Of Birth",
    component: lazy(() => import("./DateOfBirth")),
    url: "/top/date-of-birth-and-risk-of-harm",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "GP Contact",
    component: lazy(() => import("./GPContact")),
    url: "/top/gp-contact",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Pregnancy Support",
    component: lazy(() => import("./PregnancySupport")),
    url: "/top/support-care",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "UK GP Registration",
    component: lazy(() => import("./GPRegistration")),
    url: "/top/uk-gp-registration",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "UBRN and Passcode",
    component: lazy(() => import("./UBRNPasscode")),
    url: "/top/ubrn-passcode",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "MSI UK Private Treatment",
    component: lazy(() => import("./MSIUKPrivateTreatment")),
    url: "/top/msi-uk-private-treatment",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Private Booking Costs",
    component: lazy(() => import("./PrivateBookingCosts")),
    url: "/top/private-booking-costs",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Hope House Clinic",
    component: lazy(() => import("./HopeHouseClinic")),
    url: "/top/hope-house-clinic",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Bluebell Suite FPC Code",
    component: lazy(() => import("./BluebellSuiteFPCCode")),
    url: "/top/bluebell-suite-fpc-code",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Bluebell Suite FPC Direct Services",
    component: lazy(() => import("./BluebellSuiteFPCDirectServices")),
    url: "/top/bluebell-suite-fpc-direct-services",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "GP Contract Confidentials",
    component: lazy(() => import("./GPContractConfidentials")),
    url: "/top/gp-contract-bound-confidentiality-and-data-protection-laws",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "GP Contact CBS",
    component: lazy(() => import("./GPContactCBS")),
    url: "/top/gp-contact-cbs",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "CBS MSI Contract Mandatory",
    component: lazy(() => import("./CBSMSIContractMandatory")),
    url: "/top/cbs-msi-contract-mandatory",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "CBS Non MSI Contract Mandatory",
    component: lazy(() => import("./CBSNonMSIContractMandatory")),
    url: "/top/cbs-non-msi-contract-mandatory",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "CBS MSI Contract Optional",
    component: lazy(() => import("./CBSMSIContractOptional")),
    url: "/top/cbs-msi-contract-optional",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "CBS Non MSI Contract Optional",
    component: lazy(() => import("./CBSNonMSIContractOptional")),
    url: "/top/cbs-non-msi-contract-optional",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Awaiting For Booking",
    component: lazy(() => import("./AwaitingForBooking")),
    url: "/top/awaiting-for-booking",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Counselling Around Your Pregnancy Options",
    component: lazy(() => import("./CounsellingAroundPregnancy")),
    url: "/top/counselling-around-your-pregnancy-options",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Next Steps for Treatment",
    component: lazy(() => import("./NextStepsTreatment")),
    url: "/top/next-steps-treatment",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Try Our Services",
    component: lazy(() => import("./TryOurServices")),
    url: "/top/try-our-services",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },

  //TODO: Not using below page
  {
    title: "Phone Number For Call",
    component: lazy(() => import("./PhoneNumberForCall")),
    url: "/top/phone-number-for-call",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Pregnancy No GP",
    component: lazy(() => import("./Pregnancy_NoGP")),
    url: "/top/PregnancyNoGP",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Pregnancy NONResident",
    component: lazy(() => import("./Pregnancy_NONResident")),
    url: "/top/PregnancyNonresident",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Counselling Appointments",
    component: lazy(() => import("./CounsellingAppointments")),
    url: "/top/free-counselling-appointments",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
];
