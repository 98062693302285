import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IQuestionAnswerAPI,
  questionTypes,
} from "../components/questionnaires/questionnaire-builder/QuestionnarieBuilderOptions";
import { questionnariePatientAnswer } from "../services/api/QuestionnarieApi";

export const larcAppointmentSlice = createSlice({
  name: "larc_appointment",
  initialState: {},
  reducers: {
    setLARCAppointment: (state: any, action: PayloadAction<object>) => {
      state.data = action.payload;
      if (state.data.status !== "completed" && state.data.id) {
        const answer: any = [];
        state?.data?.QuestionSetIds?.questions.map((question: any) => {
          if (question.type === questionTypes.multiplechoice) {
            question.userAnswer.map((ans: any) => {
              answer.push({
                answer: ans?.answer,
                optionId: ans?.optionId,
                questionId: question.id,
              });
            });
          } else {
            answer.push({
              answer: question.userAnswer?.[0]?.answer,
              optionId: question.userAnswer?.[0]?.optionId,
              questionId: question.id,
            });
          }
        });
        const answerPayload: IQuestionAnswerAPI = {
          shareId: state.data.id,
          questionSetId: state.data.questionSetId,
          status:
            action?.payload &&
              Object.prototype.hasOwnProperty.call(
                action?.payload,
                "isCounselling"
              )
              ? "completed"
              : "draft",
          answers: answer,
        };
        questionnariePatientAnswer(answerPayload);
      }
    },
    submitLARCAppointment: (state: any, action: PayloadAction<object>) => {
      state.data = action.payload;
      const answer: any = [];
      state?.data?.QuestionSetIds?.questions.map((question: any) => {
        if (question.type === questionTypes.multiplechoice) {
          question.userAnswer.map((ans: any) => {
            answer.push({
              answer: ans?.answer,
              optionId: ans?.optionId,
              questionId: question.id,
            });
          });
        } else {
          answer.push({
            answer: question.userAnswer?.[0]?.answer,
            optionId: question.userAnswer?.[0]?.optionId,
            questionId: question.id,
          });
        }
      });
      const answerPayload: IQuestionAnswerAPI = {
        shareId: state.data.id,
        questionSetId: state.data.questionSetId,
        status: "hold",
        answers: answer,
      };
      questionnariePatientAnswer(answerPayload);
    },
  },
});

export const { setLARCAppointment, submitLARCAppointment } =
  larcAppointmentSlice.actions;

export default larcAppointmentSlice.reducer;
