import configJson from '../config/config';

const siteUrlsJson = {
  ourSiteUrls: {
    getPages: 'cms/pages/get',
    modifyPages: 'cms/pages/modify',
    login: 'cms/auth/login',
    fileUpload: 'cms/file/upload-img',
    getUploads: 'cms/file/get-images-names',
    deleteUpload: 'cms/file/delete-file?filename=',
    getAllThemeList: 'cms/global-settings/theme-config',
    postTheme: 'cms/global-settings/theme-config',
  },
  outerDomainUrls: {},
};

function checkInnerJson(jsonData: any) {
  if (jsonData) {
    for (const key in jsonData) {
      if (typeof jsonData[key] === 'string') {
        jsonData[key] = `${configJson.tinaServer}${jsonData[key]}`;
      } else {
        jsonData[key] = checkInnerJson(jsonData[key]);
      }
    }
  }
  return jsonData as typeof siteUrlsJson.ourSiteUrls;
}

export const tinaUrls = {
  ...checkInnerJson(siteUrlsJson.ourSiteUrls),
  outerDomainUrls: siteUrlsJson.outerDomainUrls,
};
