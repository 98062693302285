export const questionTypes = {
    multiplechoice: "multiplechoice",
    statement: "statement",
    dateinput: "dateinput",
    numberinput: "numberinput",
    picturechoice: "picturechoice",
    yesnoquestion: "yesnoquestion",
    rating: "rating",
    likert: "likert",
    dropdown: "dropdown",
    section: "section",
    openingstatement: "openingstatement",
    closingstatement: "closingstatement",
    freetext: "freetext",
    conditionalsegment: "conditionalsegment",
    statementlist: "statementlist",
    phonecall: "phonecall",
    radioinput: "radioinput"
};

export const questionTypeIntialState: any = {
    dateinput: false,
    numberinput: false,
    multiplechoice: false,
    statement: false,
    picturechoice: false,
    yesnoquestion: false,
    rating: false,
    likert: false,
    dropdown: false,
    section: false,
    freetext: false,
    openingstatement: false,
    closingstatement: false,
    conditionalsegment: false,
    statementlist: false,
    phonecall: false,
    radioinput: false,
};

export interface IQuestionObject {
    id: string;
    type: string;
    question: string;
    isMandatory: boolean;
    description: string | null;
    hasDescription: boolean;
    hasConditionalQuestion: boolean;
    hasTriggerQuestion: boolean;
    hasImage: boolean;
    internal: string | false;
    imageUrl: string | null;
    dependentQuestionId: string | null;
    dependentOptionId: string | null;
    sortOrder: number;
    questionSetId: string | null;
}

export interface IQuestionOptionsObject {
    id: string;
    text: any;
    range: number | null;
    rangeDescription: string | null;
    questionId: string;
    sortOrder: number;
    keywords?: Array<string> | null;
    rightcare?: Array<string> | null;
    telemedicine?: Array<string> | null;
    safeguarding?: Array<string> | null;
    score?: number | null;
    condition?: string;
    irToAdmin?: Array<string> | null,
    irToClinic?: Array<string> | null
}

export interface IQuestionPayloadObj {
    id?: string;
    name: string;
    version: string;
    description: string | null;
    copyRight: boolean;
    pageNumber: boolean;
    category: string | null;
    createdBy?: string;
    service: string | null;
    questions: Array<IQuestionObject>;
    options: Array<IQuestionOptionsObject>;
    public?: boolean;
    status?: string | null;
    estimateTime: string;
    trigerForm: string | null;
    trigerByCategory: Array<object>;
    tags: Array<string>;
    isStaticForm: boolean;
    url: string | null;
    shareID?: string;
}

export interface IQuestionCommentPayloadObj {
    id?: string;
    questionSetId: string;
    comment: string;
    createdBy: string;
    createdAt: string;
    updatedAt: string;
}

export interface IQuestionAnswerAPI {
    questionSetId: string;
    status: string | null;
    shareId?: string;
    answers: Array<any>;
    pendingCallbackStatus?: boolean;
}

export interface IMinicardQuestion {
    id: string;
    name: string;
    copyRight: boolean;
    pageNumber: boolean | null;
    category: string;
    ageRange: string;
    tags: Array<string>;
    canDelete: boolean;
    isFavourite: boolean;
    status: "approved" | "rejected" | "sent for approval" | "draft";
    updatedAt: string;
    questionSetCategory: { name: string };
    questionSetAgeRange: { name: string };
    public: boolean;
    ispublic: boolean;
    copyrighted: boolean;
    questionShared?: Array<any>;
    createdBy?: string;
    isStaticForm: boolean;
    url: string;
    service: string;
}

export const questionObject: IQuestionObject = {
    id: "",
    type: "",
    question: "",
    isMandatory: true,
    hasDescription: false,
    hasConditionalQuestion: false,
    hasTriggerQuestion: false,
    hasImage: false,
    description: null,
    imageUrl: null,
    internal: "",
    questionSetId: null,
    dependentQuestionId: null,
    dependentOptionId: null,
    sortOrder: 0,
};

export const questionOptionsObject: IQuestionOptionsObject = {
    id: "",
    text: null,
    range: null,
    rangeDescription: null,
    questionId: "",
    sortOrder: 0,
    keywords: null,
    rightcare: null,
    telemedicine: null,
    safeguarding: null,
    score: null,
};
export const questionPayloadObject: IQuestionPayloadObj = {
    name: "",
    version: "",
    description: null,
    copyRight: false,
    pageNumber: false,
    category: null,
    service: null,
    public: false,
    questions: [],
    options: [],
    estimateTime: "",
    trigerForm: null,
    trigerByCategory: [],
    tags: [],
    isStaticForm: false,
    url: null,
    createdBy: ""
};

export interface IQuestionApprovalObj {
    receviedRecord: Array<IQuestionPayloadObj>;
    reviewedRecord: Array<IQuestionPayloadObj>;
}

export const questionApprovalObject: IQuestionApprovalObj = {
    receviedRecord: [],
    reviewedRecord: [],
};

export const questionIntialText = "";

export const conditionOptions = {
    range: "range",
    greater: "greater",
    lesser: "lesser",
    before: "before",
    after: "after",
};
