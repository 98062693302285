let backendDomain;
let tinaServer;

if (process.env.NODE_ENV === "production") {
  backendDomain = process.env.REACT_APP_SERVICE_PHR_BACKEND;
  tinaServer = window.location.origin;
} else {
  backendDomain = process.env.REACT_APP_SERVICE_PHR_BACKEND;
  tinaServer = process.env.REACT_APP_SERVICE_TINA_BACKEND;
}

tinaServer = tinaServer
  ? tinaServer.endsWith("/")
    ? tinaServer
    : `${tinaServer}/`
  : "";

backendDomain = backendDomain
  ? backendDomain.endsWith("/")
    ? backendDomain
    : `${backendDomain}/`
  : "";

const configJson = {
  backendDomain,
  tinaServer,
};

export const siteConfig = {
  nhs: {
    client_id: process.env.REACT_APP_NHS_LOGIN_CLIENT_ID,
    authorization_url: process.env.REACT_APP_NHS_LOGIN_AUTHORIZATION_URL,
    audience: process.env.REACT_APP_NHS_LOGIN_AUDIENCE,
    token_host: process.env.REACT_APP_NHS_LOGIN_TOKEN_HOST,
    key: process.env.REACT_APP_NHS_LOGIN_KEY_PATH,
    client_redirect_url: process.env.REACT_APP_NHS_LOGIN_CLIENT_REDIRECT_URL,
  },
};

export default configJson;

export const config = {
  nhs: {
    link: {
      abortion: "https://www.nhs.uk/conditions/abortion/",
      findNhsNumber:
        "https://www.nhs.uk/nhs-services/online-services/find-nhs-number/",
    },
  },
};
