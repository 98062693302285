import { lazy } from "react";

export default [
  {
    title: "Last Period",
    component: lazy(() => import("./LastPeriod")),
    url: "/eligibility/lastperiod",
    heading: "Last Period",
    requirePermission: false,
  },
  {
    title: "GP Details",
    component: lazy(() => import("./GPDetails")),
    url: "/eligibility/gpdetails",
    heading: "Last Period",
    requirePermission: false,
  },
  {
    title: "UBRN",
    component: lazy(() => import("./UBRN")),
    url: "/eligibility/ubrn",
    heading: "UBRN",
    requirePermission: false,
  },
  {
    title: "Contract Availability",
    component: lazy(() => import("./Contract")),
    url: "/eligibility/contract",
    heading: "Contract Availability",
    requirePermission: false,
  },
  {
    title: "Contract Availability Local Provider",
    component: lazy(() => import("./LocalProvider")),
    url: "/eligibility/localprovider",
    heading: "Contract Availability",
    requirePermission: false,
  },
  {
    title: "Cost",
    component: lazy(() => import("./Cost")),
    url: "/eligibility/cost",
    heading: "Cost",
    requirePermission: false,
  },
  {
    title: "Consultation Form",
    component: lazy(() => import("./ConsultationForm")),
    url: "/eligibility/consultationform",
    heading: "Consultation Form",
    requirePermission: false,
  },
];
