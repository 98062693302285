import React from "react";
import "../style/components/Button.css";

interface ButtonProps {
  addClass?: string;
  text?: string;
  children?: any;
  type: "submit" | "button";
  disabled?: boolean;
  onClick?: any;
  id?: string;
  src?: any;
}

export const Button: React.FC<ButtonProps> = ({
  addClass,
  text,
  children,
  type,
  disabled,
  onClick,
  id,
  src,
}) => {
  return (
    <button
      id={id}
      onClick={onClick}
      type={type === "button" ? "button" : "submit"}
      className={`btn ${addClass}`}
      disabled={disabled}
    >
      {src && <img src={src} />}
      {text}
      {children}
    </button>
  );
};
