import { lazy } from "react";

export default [
  {
    title: "Pregnancy Abortion ",
    component: lazy(() => import("./Pregnancy_abortion")),
    url: "/larc/eligibility",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Pregnancy Abortion ",
    component: lazy(() => import("./Pregnancy_abortion")),
    url: "/larc/eligibility/:id/:shareId",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Pregnancy DOB",
    component: lazy(() => import("./Pregnancy_DOB")),
    url: "/larc/date-of-birth",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Pregnancy MobileNO",
    component: lazy(() => import("./Pregnancy_mobileNo")),
    url: "/larc/mobileNo",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Pregnancy awaiting",
    component: lazy(() => import("./Pregnancy_awaiting")),
    url: "/larc/awaiting",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Pregnancy GP Details",
    component: lazy(() => import("./Pregnancy_GPDetails")),
    url: "/larc/GPDetails",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Pregnancy GP Details",
    component: lazy(() => import("./Pregnancy_GPDetails")),
    url: "/larc/GPDetails/:id/:shareId",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Pregnancy medical history",
    component: lazy(() => import("./Pregnancy_medicalhistory")),
    url: "/larc/medicalhistory",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Pregnanc Route",
    component: lazy(() => import("./Pregnancy_Route")),
    url: "/larc/route",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "PregnancySign Posting",
    component: lazy(() => import("./Pregnancy_Signposting")),
    url: "/larc/PregnancySignposting",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "LarcRed Rightcare",
    component: lazy(() => import("./Larc_red_rightcare")),
    url: "/larc/RedRightcare",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
];
