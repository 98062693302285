import { IFilter } from './TriageType';
import moment from "moment";

// Disable the filter submit button. It becomes visible when at least one filter value is entered.
export const disableSubmitBtn = (filter: IFilter) => {
  const isFilterActive =
    filter.ageFrom === 0 ||
    filter.ageFrom ||
    filter.ageTo === 0 ||
    filter.ageTo ||
    filter.dateTo ||
    filter.dateFrom ||
    filter.from ||
    filter.to ||
    (filter.services && filter.services.length) ||
    (filter.status && filter.status.length) ||
    (filter.safety && filter.safety.length) ||
    (filter.others && filter.others.length) ||
    (filter.rightcareTriggers && filter.rightcareTriggers.length) ||
    (filter.categories && filter.categories.length)
  return isFilterActive;
}
function getStartOfDay(lmpDate: any) {
  const date = new Date(lmpDate);
  date.setHours(0, 0, 0, 0);
  return date;
}
// calculate gestation in weeks and days based on last menstrual period (LMP)
export const calculateGestation = (lmpDate: Date | string) => {
  const lmp = getStartOfDay(lmpDate);
  const currentDate = new Date();
  // Calculate the difference in milliseconds between the LMP date and the current date
  const differenceMs = currentDate.getTime() - lmp.getTime();
  // Convert the difference from milliseconds to days
  const gestationDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
  // Calculate weeks and days from gestation in days
  const weeks = Math.floor(gestationDays / 7);
  const days = gestationDays % 7;
  const overallDays = gestationDays;
  return { weeks, days, overallDays };
};

export const categoryWiseValue = (category: string) => {
  const result = category === "category 1"
    ? "categoryOne"
    : category === "category 2"
      ? "categoryTwo"
      : category === "category 3"
        ? "categoryThree"
        : "categoryFour";
  return result;
}

// check filter object contains all default values it return true
export const hasContainsAllFilterDefaultValues = (filterObj: IFilter) => {
  const filterValue = Object.values(filterObj) || [];
  return filterValue.every((item) => {
    if (Array.isArray(item)) {
      return item.length === 0; // Check if item is an empty array
    } else {
      return item === ''; // Check if item is an empty string
    }
  });
}

// triage filter set minimum date
export const triageSetMinimumDate = (startFromDate: Date | null) => {
  const fromDate = moment(startFromDate);
  // console.log('date isValid :>>', fromDate.isValid());  
  if (fromDate.isValid()) {
    // Start date in YYYY-MM-DD format
    const startDateString = moment(startFromDate).format("YYYY-MM-DD");

    // Convert the start date string to a JavaScript Date object
    const startDate = new Date(startDateString);

    // Subtract one day (24 hours) in milliseconds
    const oneDayInMillis = 24 * 60 * 60 * 1000;
    const previousDate = new Date(startDate.getTime() - oneDayInMillis);
    // console.log('previousDate 75 :>>', previousDate);

    // Format the previous date as YYYY-MM-DD
    const previousDateString = previousDate.toISOString().split('T')[0];
    // console.log('Previous Date:', previousDateString);
    return previousDateString;
  } else {
    return startFromDate;
  }
}