import { lazy } from 'react';

export default [
  {
    title: 'Pregnancy NHS',
    component: lazy(() => import('./Pregnancy_NHS')),
    url: '/vas/nhs',
    requirePermission: true,
    roles: ['Super_Admin', 'Admin', 'Client'],
  },
  {
    title: 'Pregnancy NHS',
    component: lazy(() => import('./Pregnancy_NHS')),
    url: '/vas/nhs/:id/:shareId',
    requirePermission: true,
    roles: ['Super_Admin', 'Admin', 'Client'],
  },
  {
    title: 'Pregnancy DOB',
    component: lazy(() => import('./Pregnancy_DOB')),
    url: '/vas/date-of-birth',
    requirePermission: true,
    roles: ['Super_Admin', 'Admin', 'Client'],
  },
  {
    title: 'Pregnancy GP Details',
    component: lazy(() => import('./Pregnancy_GPDetails')),
    url: '/vas/GPDetails',
    requirePermission: true,
    roles: ['Super_Admin', 'Admin', 'Client'],
  },
  {
    title: 'Pregnancy UBRN',
    component: lazy(() => import('./Pregnancy_UBRN')),
    url: '/vas/UBRN',
    requirePermission: true,
    roles: ['Super_Admin', 'Admin', 'Client'],
  },
  {
    title: 'Pregnancy medical history',
    component: lazy(() => import('./Pregnancy_medicalhistory')),
    url: '/vas/medicalhistory',
    requirePermission: true,
    roles: ['Super_Admin', 'Admin', 'Client'],
  },
  {
    title: 'Pregnancy NOTLocal',
    component: lazy(() => import('./Pregnancy_NOTLocal')),
    url: '/vas/notLocal',
    requirePermission: true,
    roles: ['Super_Admin', 'Admin', 'Client'],
  },
  {
    title: 'Pregnancy MobileNO',
    component: lazy(() => import('./Pregnancy_mobileNo')),
    url: '/vas/mobileNo',
    requirePermission: true,
    roles: ['Super_Admin', 'Admin', 'Client'],
  },
  {
    title: 'Pregnancy awaiting',
    component: lazy(() => import('./Pregnancy_awaiting')),
    url: '/vas/awaiting',
    requirePermission: true,
    roles: ['Super_Admin', 'Admin', 'Client'],
  },
  {
    title: 'PregnancySign Posting',
    component: lazy(() => import('./Pregnancy_Signposting')),
    url: '/vas/signposting',
    requirePermission: true,
    roles: ['Super_Admin', 'Admin', 'Client'],
  },
  {
    title: 'Pregnanc Route',
    component: lazy(() => import('./Pregnancy_Route')),
    url: '/vas/route',
    requirePermission: true,
    roles: ['Super_Admin', 'Admin', 'Client'],
  },
  {
    title: 'Pregnanc Counselling',
    component: lazy(() => import('./Pregnancy_Counselling')),
    url: '/vas-eligibility/counselling',
    requirePermission: true,
    roles: ['Super_Admin', 'Admin', 'Client'],
  },
  {
    title: 'Pregnancy Route UK GP',
    component: lazy(() => import('./Pregnancy_RouteUKGP')),
    url: '/vas/routeGP',
    requirePermission: true,
    roles: ['Super_Admin', 'Admin', 'Client'],
  },
];
