import { lazy } from "react";

export default [
    {
        title: "LarcRed Rightcare",
        component: lazy(() => import("./LarcRightCareRed")),
        url: "/larc/RightcareRed",
        requirePermission: true,
        roles: ["Super_Admin", "Admin", "Client"],
    },
    {
        title: "LarcBook Appointment",
        component: lazy(() => import("./LarcBookAppointment")),
        url: "/larc/appointment/:id/:shareId",
        requirePermission: true,
        roles: ["Super_Admin", "Admin", "Client"],
    },
    {
        title: "LarcBook Appointment",
        component: lazy(() => import("./BookingConfirmation")),
        url: "/larc/booking-confirmed/:id/:shareId",
        requirePermission: true,
        roles: ["Super_Admin", "Admin", "Client"],
    }

];
