import { lazy } from "react";

export default [
  {
    title: "Home Page",
    component: lazy(() => import("./Home")),
    url: "/external",
    requirePermission: false,
  },
  // {
  //     title: 'Staff Login',
  //     component: lazy(() => import('./external-login')),
  //     url: '/external-login',
  //     requirePermission: false,
  //     roles: ['Super_Admin','Admin','User'],
  // },
  // {
  //   title: "Staff Azure Login",
  //   component: lazy(() => import("./external-azure-login")),
  //   url: "/external-azure-login",
  //   requirePermission: false,
  // },

  // {
  //     title: 'Azure Login',
  //     component: lazy(() => import('./azure_redirect')),
  //     url: '/azure_redirect',
  //     requirePermission: false,
  //     roles: ['Super_Admin','Admin','User'],
  // }
];
