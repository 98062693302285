import axiosAPI from '../middlewares/axios-interceptor';

const createSession = (data: any) => axiosAPI.post('/sessions', data);

const deleteSession = (data: any) => axiosAPI.delete('/sessions', data);

const signIn = (data: any) => axiosAPI.post('/signin', data);

const staffLogin = (data:any)=>axiosAPI.post('/staff-login',data);

const resetPassword = (data: any) => axiosAPI.post('/create-or-change-password', data);

const forgotPassword = (data:any) => axiosAPI.post('/forgotpassword', data);

const getotp = (data:any) => axiosAPI.post('/two-step-authentication',data);

const otpVerify = (data:any) => axiosAPI.post('/otpverification',data);

const logoutuser = ()=>axiosAPI.post('/logout',{});

export {
    createSession,
    deleteSession,
    signIn,
    staffLogin,
    resetPassword,
    getotp,
    forgotPassword,
    logoutuser,
    otpVerify
    
};
