import { lazy } from 'react';

export default [{
    title: 'Service Page',
    component: lazy(() => import('./Service')),
    url: '/service',
    heading:'Services',
    requirePermission: false,
    roles: ['Super_Admin','Admin'],
}];
