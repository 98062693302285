export const data= {
    "status": "success",
    "result": {
      "TOP": [
        {
          "id": "612ee462-3ae7-4750-8774-cb2cb40bc3eb",
          "name": "Sample phase 5",
          "status": "sent for approval",
          "updatedAt": "2022-11-10T06:39:01.585Z",
          "copyRight": false,
          "category": "276e3bea-d829-4af6-b2dc-5a97cc5a4221",
          "tags": [],
          "ageRange": "bcbf6ea6-a44c-4655-a32c-f0e4617070d7",
          "createdBy": "c2a79443-a414-4d05-a49f-29bfdf90653b",
          "public": false,
          "questionSetCategory": {
            "name": "Feedback form",
            "id": "276e3bea-d829-4af6-b2dc-5a97cc5a4221"
          },
          "questionSetAgeRange": {
            "name": "Paediatric"
          },
          "trustDetail": {
            "id": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
            "name": "CCC"
          },
          "questionShared": [],
          "userFavourite": [
            {
              "createdAt": "2022-03-31T04:19:05.677Z",
              "userId": "c2a79443-a414-4d05-a49f-29bfdf90653b"
            }
          ],
          "isFavourite": true,
          "canDelete": true
        },
        {
          "id": "0322932a-7cd1-4b4e-bd0e-5c81af9d0754",
          "name": "sample upload",
          "status": "sent for approval",
          "updatedAt": "2022-10-02T05:54:30.639Z",
          "copyRight": false,
          "category": "276e3bea-d829-4af6-b2dc-5a97cc5a4221",
          "tags": [],
          "ageRange": "bcbf6ea6-a44c-4655-a32c-f0e4617070d7",
          "createdBy": "c2a79443-a414-4d05-a49f-29bfdf90653b",
          "public": false,
          "questionSetCategory": {
            "name": "Feedback form",
            "id": "276e3bea-d829-4af6-b2dc-5a97cc5a4221"
          },
          "questionSetAgeRange": {
            "name": "Paediatric"
          },
          "trustDetail": {
            "id": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
            "name": "CCC"
          },
          "questionShared": [],
          "userFavourite": [
            {
              "createdAt": "2022-03-31T04:18:34.807Z",
              "userId": "c2a79443-a414-4d05-a49f-29bfdf90653b"
            }
          ],
          "isFavourite": true,
          "canDelete": true
        },
        {
          "id": "5f5bf283-9e70-4f3f-ad98-a1508fb0b484",
          "name": "Copy_kissan jam",
          "status": "sent for approval",
          "updatedAt": "2022-09-29T06:13:02.838Z",
          "copyRight": false,
          "category": "b6a4a5c7-9b9d-4349-8a26-e493b130277f",
          "tags": [],
          "ageRange": "23f66658-3472-4841-8e2d-66f8f0bc1386",
          "createdBy": "c2a79443-a414-4d05-a49f-29bfdf90653b",
          "public": true,
          "questionSetCategory": {
            "name": "Patient measure",
            "id": "b6a4a5c7-9b9d-4349-8a26-e493b130277f"
          },
          "questionSetAgeRange": {
            "name": "Geriatric"
          },
          "trustDetail": {
            "id": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
            "name": "CCC"
          },
          "questionShared": [],
          "userFavourite": [
            {
              "createdAt": "2022-03-31T04:18:40.777Z",
              "userId": "c2a79443-a414-4d05-a49f-29bfdf90653b"
            }
          ],
          "isFavourite": true,
          "canDelete": true
        },
        {
          "id": "4285e86a-256c-43f4-a10e-a6870beb078d",
          "name": "Test form",
          "status": "approved",
          "updatedAt": "2022-09-15T11:08:53.731Z",
          "copyRight": false,
          "category": "276e3bea-d829-4af6-b2dc-5a97cc5a4221",
          "tags": [],
          "ageRange": "bcbf6ea6-a44c-4655-a32c-f0e4617070d7",
          "createdBy": "c2a79443-a414-4d05-a49f-29bfdf90653b",
          "public": false,
          "questionSetCategory": {
            "name": "Feedback form",
            "id": "276e3bea-d829-4af6-b2dc-5a97cc5a4221"
          },
          "questionSetAgeRange": {
            "name": "Paediatric"
          },
          "trustDetail": {
            "id": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
            "name": "CCC"
          },
          "questionShared": [
            {
              "createdAt": "2022-09-21T16:48:02.514Z"
            },
            {
              "createdAt": "2022-10-03T08:59:02.183Z"
            },
            {
              "createdAt": "2022-10-02T05:24:02.597Z"
            },
            {
              "createdAt": "2022-10-02T05:24:02.600Z"
            }
          ],
          "userFavourite": [
            {
              "createdAt": "2022-03-17T20:14:28.000Z",
              "userId": "c2a79443-a414-4d05-a49f-29bfdf90653b"
            }
          ],
          "isFavourite": true,
          "canDelete": false
        },
        {
          "id": "ecaf0825-bbf9-4673-a052-7c8ecad6d0eb",
          "name": "Sample test covid",
          "status": "approved",
          "updatedAt": "2022-05-17T09:15:04.766Z",
          "copyRight": false,
          "category": "276e3bea-d829-4af6-b2dc-5a97cc5a4221",
          "tags": [
            "75aca07a-aeb8-40d5-b620-5aad47ad4112",
            "46db6ff0-a3d7-4790-bd8b-9f197abc9e80",
            "76999bee-2cb3-430e-b08a-7c1a429a16fd"
          ],
          "ageRange": "bcbf6ea6-a44c-4655-a32c-f0e4617070d7",
          "createdBy": "c2a79443-a414-4d05-a49f-29bfdf90653b",
          "public": false,
          "questionSetCategory": {
            "name": "Feedback form",
            "id": "276e3bea-d829-4af6-b2dc-5a97cc5a4221"
          },
          "questionSetAgeRange": {
            "name": "Paediatric"
          },
          "trustDetail": {
            "id": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
            "name": "CCC"
          },
          "questionShared": [
            {
              "createdAt": "2022-05-17T09:16:09.911Z"
            },
            {
              "createdAt": "2022-08-10T09:54:02.767Z"
            },
            {
              "createdAt": "2022-08-10T09:54:02.904Z"
            },
            {
              "createdAt": "2022-09-06T08:48:34.337Z"
            },
            {
              "createdAt": "2022-09-14T16:11:03.120Z"
            },
            {
              "createdAt": "2022-09-15T10:53:03.045Z"
            },
            {
              "createdAt": "2022-09-19T16:29:21.906Z"
            },
            {
              "createdAt": "2022-09-19T16:29:21.907Z"
            },
            {
              "createdAt": "2022-09-19T16:33:45.620Z"
            },
            {
              "createdAt": "2022-09-19T16:33:45.622Z"
            },
            {
              "createdAt": "2022-09-19T16:33:55.082Z"
            },
            {
              "createdAt": "2022-09-19T16:33:55.018Z"
            },
            {
              "createdAt": "2022-09-22T09:31:20.267Z"
            },
            {
              "createdAt": "2022-07-27T11:19:15.732Z"
            },
            {
              "createdAt": "2022-10-03T08:59:02.206Z"
            },
            {
              "createdAt": "2022-08-01T06:10:28.326Z"
            }
          ],
          "userFavourite": [
            {
              "createdAt": "2022-06-16T13:13:27.155Z",
              "userId": "c2a79443-a414-4d05-a49f-29bfdf90653b"
            },
            {
              "createdAt": "2022-06-16T13:13:27.151Z",
              "userId": "c2a79443-a414-4d05-a49f-29bfdf90653b"
            },
            {
              "createdAt": "2022-06-16T13:13:27.157Z",
              "userId": "c2a79443-a414-4d05-a49f-29bfdf90653b"
            }
          ],
          "isFavourite": true,
          "canDelete": false
        },
        {
          "id": "673f1b4e-89b7-48e3-94bd-e0f86fc24e11",
          "name": "Copy_Test form",
          "status": "draft",
          "updatedAt": "2022-03-14T05:18:19.736Z",
          "copyRight": false,
          "category": "276e3bea-d829-4af6-b2dc-5a97cc5a4221",
          "tags": [],
          "ageRange": "bcbf6ea6-a44c-4655-a32c-f0e4617070d7",
          "createdBy": "c2a79443-a414-4d05-a49f-29bfdf90653b",
          "public": false,
          "questionSetCategory": {
            "name": "Feedback form",
            "id": "276e3bea-d829-4af6-b2dc-5a97cc5a4221"
          },
          "questionSetAgeRange": {
            "name": "Paediatric"
          },
          "trustDetail": {
            "id": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
            "name": "CCC"
          },
          "questionShared": [],
          "userFavourite": [
            {
              "createdAt": "2022-03-17T20:14:18.167Z",
              "userId": "c2a79443-a414-4d05-a49f-29bfdf90653b"
            }
          ],
          "isFavourite": true,
          "canDelete": true
        }
      ],
      "favouriteCount": 8,
      "recentApprovals": [
        {
          "id": "612ee462-3ae7-4750-8774-cb2cb40bc3eb",
          "name": "Sample phase 5",
          "status": "sent for approval",
          "updatedAt": "2022-11-10T06:39:01.585Z",
          "copyRight": false,
          "category": "276e3bea-d829-4af6-b2dc-5a97cc5a4221",
          "tags": [],
          "ageRange": "bcbf6ea6-a44c-4655-a32c-f0e4617070d7",
          "createdBy": "c2a79443-a414-4d05-a49f-29bfdf90653b",
          "public": false,
          "questionSetCategory": {
            "name": "Feedback form",
            "id": "276e3bea-d829-4af6-b2dc-5a97cc5a4221"
          },
          "questionShared": [],
          "userFavourite": [
            {
              "id": "291332a6-5544-42a7-a91e-2a3eea313323",
              "questionSetId": "612ee462-3ae7-4750-8774-cb2cb40bc3eb",
              "userId": "c2a79443-a414-4d05-a49f-29bfdf90653b",
              "createdAt": "2022-03-31T04:19:05.677Z",
              "updatedAt": "2022-03-31T04:19:05.677Z"
            }
          ],
          "trustDetail": {
            "id": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
            "name": "CCC"
          },
          "questionApprove": [
            {
              "status": "Pending",
              "updatedAt": "2022-11-10T05:51:57.044Z"
            },
            {
              "status": "Pending",
              "updatedAt": "2022-09-22T13:18:04.032Z"
            },
            {
              "status": "Pending",
              "updatedAt": "2022-03-24T10:23:34.567Z"
            }
          ],
          "isFavourite": true,
          "canDelete": true
        },
        {
          "id": "74b4ea7e-c020-421c-a808-2847e39afe95",
          "name": "QB1",
          "status": "sent for approval",
          "updatedAt": "2022-11-01T12:17:38.483Z",
          "copyRight": false,
          "category": "786d7d03-d12b-4dc2-b66f-be7ae5bd3541",
          "tags": [],
          "ageRange": "4eba7e5e-05c9-4e64-abb6-d20b27713fcb",
          "createdBy": "c2a79443-a414-4d05-a49f-29bfdf90653b",
          "public": false,
          "questionSetCategory": {
            "name": "Covid",
            "id": "786d7d03-d12b-4dc2-b66f-be7ae5bd3541"
          },
          "questionShared": [],
          "userFavourite": [],
          "trustDetail": {
            "id": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
            "name": "CCC"
          },
          "questionApprove": [
            {
              "status": "Pending",
              "updatedAt": "2022-11-01T12:17:38.448Z"
            }
          ],
          "isFavourite": false,
          "canDelete": true
        },
        {
          "id": "95f52c8d-92fb-4c67-9fef-8b37099f305f",
          "name": "MSi11111",
          "status": "approved",
          "updatedAt": "2022-11-01T04:53:20.081Z",
          "copyRight": false,
          "category": "276e3bea-d829-4af6-b2dc-5a97cc5a4221",
          "tags": [],
          "ageRange": "bcbf6ea6-a44c-4655-a32c-f0e4617070d7",
          "createdBy": "c2a79443-a414-4d05-a49f-29bfdf90653b",
          "public": true,
          "questionSetCategory": {
            "name": "Feedback form",
            "id": "276e3bea-d829-4af6-b2dc-5a97cc5a4221"
          },
          "questionShared": [],
          "userFavourite": [],
          "trustDetail": {
            "id": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
            "name": "CCC"
          },
          "questionApprove": [
            {
              "status": "approved",
              "updatedAt": "2022-11-01T04:53:03.162Z"
            }
          ],
          "isFavourite": false,
          "canDelete": true
        },
        {
          "id": "f1add92e-e731-418b-a999-fa3b6e3faac2",
          "name": "test kjfsd7fdsd",
          "status": "sent for approval",
          "updatedAt": "2022-10-20T08:53:57.480Z",
          "copyRight": false,
          "category": "276e3bea-d829-4af6-b2dc-5a97cc5a4221",
          "tags": [
            "3a2224fc-028e-4993-8d08-20b8a5dc820e"
          ],
          "ageRange": "bcbf6ea6-a44c-4655-a32c-f0e4617070d7",
          "createdBy": "c2a79443-a414-4d05-a49f-29bfdf90653b",
          "public": false,
          "questionSetCategory": {
            "name": "Feedback form",
            "id": "276e3bea-d829-4af6-b2dc-5a97cc5a4221"
          },
          "questionShared": [],
          "userFavourite": [],
          "trustDetail": {
            "id": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
            "name": "CCC"
          },
          "questionApprove": [
            {
              "status": "Pending",
              "updatedAt": "2022-10-20T08:53:57.447Z"
            }
          ],
          "isFavourite": false,
          "canDelete": true
        },
        {
          "id": "f8e67b45-a661-4631-b3d9-fb1f6c219ac3",
          "name": "MSI43",
          "status": "approved",
          "updatedAt": "2022-10-17T10:12:05.683Z",
          "copyRight": false,
          "category": "b22262fa-25ca-425f-9852-ed110ea6f934",
          "tags": [
            "84c4cefa-4814-4e90-818d-77e23acc42c0"
          ],
          "ageRange": "23f66658-3472-4841-8e2d-66f8f0bc1386",
          "createdBy": "c2a79443-a414-4d05-a49f-29bfdf90653b",
          "public": true,
          "questionSetCategory": {
            "name": "Patient research form",
            "id": "b22262fa-25ca-425f-9852-ed110ea6f934"
          },
          "questionShared": [
            {
              "id": "8230abee-be97-47d8-9287-667b43574672",
              "questionSetId": "f8e67b45-a661-4631-b3d9-fb1f6c219ac3",
              "sharedBy": "09ac1ccd-92db-41a0-93e2-a700bd75aa8c",
              "sharedTo": "8ec60ea3-f64a-4b89-9fb6-cb21db26b560",
              "status": "completed",
              "completedDate": "2022-10-19T05:24:17.126Z",
              "responseViewBySender": null,
              "questionnaireViewByReceiver": true,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": null,
              "dueDate": null,
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
              "createdAt": "2022-10-19T05:21:47.176Z",
              "updatedAt": "2022-10-19T05:24:17.353Z"
            },
            {
              "id": "6f556b7e-2627-4d2e-89a0-d5608952bda7",
              "questionSetId": "f8e67b45-a661-4631-b3d9-fb1f6c219ac3",
              "sharedBy": "c2a79443-a414-4d05-a49f-29bfdf90653b",
              "sharedTo": "d847c6b5-9ae2-47c6-88b9-5c2f0cd57d84",
              "status": "completed",
              "completedDate": "2022-10-17T10:15:05.243Z",
              "responseViewBySender": null,
              "questionnaireViewByReceiver": true,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": null,
              "dueDate": null,
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": [
                "62dfda7d3984a0196afbb1f5",
                "62dfd6963984a0196afbaf1a",
                "62dfd70c3984a0196afbafc4"
              ],
              "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
              "createdAt": "2022-10-17T10:13:36.398Z",
              "updatedAt": "2022-10-17T10:16:35.283Z"
            }
          ],
          "userFavourite": [],
          "trustDetail": {
            "id": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
            "name": "CCC"
          },
          "questionApprove": [
            {
              "status": "approved",
              "updatedAt": "2022-10-17T10:11:44.305Z"
            }
          ],
          "isFavourite": false,
          "canDelete": false
        },
        {
          "id": "aa614098-aded-4e42-8c05-d8463696dcf8",
          "name": "fdsafdsa",
          "status": "approved",
          "updatedAt": "2022-10-12T06:37:08.801Z",
          "copyRight": false,
          "category": "276e3bea-d829-4af6-b2dc-5a97cc5a4221",
          "tags": [],
          "ageRange": "a48c6f1d-66af-455b-b9fa-0224d8f8de46",
          "createdBy": "c2a79443-a414-4d05-a49f-29bfdf90653b",
          "public": false,
          "questionSetCategory": {
            "name": "Feedback form",
            "id": "276e3bea-d829-4af6-b2dc-5a97cc5a4221"
          },
          "questionShared": [],
          "userFavourite": [],
          "trustDetail": {
            "id": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
            "name": "CCC"
          },
          "questionApprove": [
            {
              "status": "approved",
              "updatedAt": "2022-10-12T06:37:08.742Z"
            }
          ],
          "isFavourite": false,
          "canDelete": true
        }
      ],
      "recentApprovalsCount": 40,
      "recentResponse": [
        {
          "updatedAt": "2022-10-17T10:16:35.283Z",
          "trustDetail": {
            "id": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
            "name": "CCC"
          },
          "sharedToUsers": {
            "id": "d847c6b5-9ae2-47c6-88b9-5c2f0cd57d84",
            "firstName": "Jack",
            "lastName": "Mckenncy",
            "email": "siva241094+p1@gmail.com"
          },
          "questionSetInfo": {
            "name": "MSI43"
          }
        },
        {
          "updatedAt": "2022-10-13T07:04:02.096Z",
          "trustDetail": {
            "id": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
            "name": "CCC"
          },
          "sharedToUsers": {
            "id": "68fb5124-b4a9-4777-8752-ed8671fedf9f",
            "firstName": "Sathish",
            "lastName": "Kumar",
            "email": "sathish@mindwaveventures.com"
          },
          "questionSetInfo": {
            "name": "test quest 22 07"
          }
        },
        {
          "updatedAt": "2022-09-22T13:20:00.915Z",
          "trustDetail": {
            "id": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
            "name": "CCC"
          },
          "sharedToUsers": {
            "id": "68fb5124-b4a9-4777-8752-ed8671fedf9f",
            "firstName": "Sathish",
            "lastName": "Kumar",
            "email": "sathish@mindwaveventures.com"
          },
          "questionSetInfo": {
            "name": "testing sample Questionnaire"
          }
        },
        {
          "updatedAt": "2022-09-22T09:39:50.354Z",
          "trustDetail": {
            "id": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
            "name": "CCC"
          },
          "sharedToUsers": {
            "id": "68fb5124-b4a9-4777-8752-ed8671fedf9f",
            "firstName": "Sathish",
            "lastName": "Kumar",
            "email": "sathish@mindwaveventures.com"
          },
          "questionSetInfo": {
            "name": "Sample test covid"
          }
        },
        {
          "updatedAt": "2022-09-22T09:26:44.130Z",
          "trustDetail": {
            "id": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
            "name": "CCC"
          },
          "sharedToUsers": {
            "id": "68fb5124-b4a9-4777-8752-ed8671fedf9f",
            "firstName": "Sathish",
            "lastName": "Kumar",
            "email": "sathish@mindwaveventures.com"
          },
          "questionSetInfo": {
            "name": "Sample test covid"
          }
        },
        {
          "updatedAt": "2022-09-06T08:28:37.743Z",
          "trustDetail": {
            "id": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
            "name": "CCC"
          },
          "sharedToUsers": {
            "id": "68fb5124-b4a9-4777-8752-ed8671fedf9f",
            "firstName": "Sathish",
            "lastName": "Kumar",
            "email": "sathish@mindwaveventures.com"
          },
          "questionSetInfo": {
            "name": "Sample test covid"
          }
        }
      ],
      "publicForms": [
        {
          "id": "074b73dd-7c4c-4dbc-ad9d-cac9d50103df",
          "name": "Health check 01",
          "status": "approved",
          "updatedAt": "2022-11-01T08:47:39.258Z",
          "copyRight": false,
          "category": "276e3bea-d829-4af6-b2dc-5a97cc5a4221",
          "tags": [],
          "ageRange": "7ef39810-89a9-4fcf-8c7f-b77e0ff0c76d",
          "createdBy": "42adb079-8111-4216-985b-1e8f8581c52d",
          "public": true,
          "questionSetCategory": {
            "name": "Feedback form",
            "id": "276e3bea-d829-4af6-b2dc-5a97cc5a4221"
          },
          "questionSetAgeRange": {
            "name": "Person"
          },
          "questionShared": [
            {
              "id": "9366da54-bb28-4f08-8c99-96d9dd97c0a2",
              "questionSetId": "074b73dd-7c4c-4dbc-ad9d-cac9d50103df",
              "sharedBy": "42adb079-8111-4216-985b-1e8f8581c52d",
              "sharedTo": "b5855ef2-533b-4ca9-bd48-4930dd60a1c0",
              "status": "completed",
              "completedDate": "2022-11-01T11:14:31.312Z",
              "responseViewBySender": null,
              "questionnaireViewByReceiver": true,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": null,
              "dueDate": null,
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": "f953492f-5f52-480d-a860-519b1e29d266",
              "createdAt": "2022-11-01T11:12:46.871Z",
              "updatedAt": "2022-11-01T11:14:31.541Z"
            },
            {
              "id": "5237e500-64a3-4e6c-be3f-6fb2fc8710bc",
              "questionSetId": "074b73dd-7c4c-4dbc-ad9d-cac9d50103df",
              "sharedBy": "42adb079-8111-4216-985b-1e8f8581c52d",
              "sharedTo": "b5855ef2-533b-4ca9-bd48-4930dd60a1c0",
              "status": "completed",
              "completedDate": "2022-11-02T05:03:33.745Z",
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": null,
              "dueDate": null,
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
              "createdAt": "2022-11-02T05:00:59.928Z",
              "updatedAt": "2022-11-02T05:03:33.886Z"
            }
          ],
          "userFavourite": [],
          "questionSetTags": [],
          "trustDetail": {
            "id": "f953492f-5f52-480d-a860-519b1e29d266",
            "name": "East Cheshire NHS Trust"
          },
          "isFavourite": false,
          "canDelete": false
        },
        {
          "id": "95f52c8d-92fb-4c67-9fef-8b37099f305f",
          "name": "MSi11111",
          "status": "approved",
          "updatedAt": "2022-11-01T04:53:20.081Z",
          "copyRight": false,
          "category": "276e3bea-d829-4af6-b2dc-5a97cc5a4221",
          "tags": [],
          "ageRange": "bcbf6ea6-a44c-4655-a32c-f0e4617070d7",
          "createdBy": "c2a79443-a414-4d05-a49f-29bfdf90653b",
          "public": true,
          "questionSetCategory": {
            "name": "Feedback form",
            "id": "276e3bea-d829-4af6-b2dc-5a97cc5a4221"
          },
          "questionSetAgeRange": {
            "name": "Paediatric"
          },
          "questionShared": [],
          "userFavourite": [],
          "questionSetTags": [],
          "trustDetail": {
            "id": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
            "name": "CCC"
          },
          "isFavourite": false,
          "canDelete": true
        },
        {
          "id": "f9baa0c6-6cd8-484b-89f0-07537fcd6e65",
          "name": "Copy_Amitytest",
          "status": "approved",
          "updatedAt": "2022-10-20T11:22:43.936Z",
          "copyRight": false,
          "category": "b22262fa-25ca-425f-9852-ed110ea6f934",
          "tags": [],
          "ageRange": "a48c6f1d-66af-455b-b9fa-0224d8f8de46",
          "createdBy": "09ac1ccd-92db-41a0-93e2-a700bd75aa8c",
          "public": true,
          "questionSetCategory": {
            "name": "Patient research form",
            "id": "b22262fa-25ca-425f-9852-ed110ea6f934"
          },
          "questionSetAgeRange": {
            "name": "age"
          },
          "questionShared": [],
          "userFavourite": [],
          "questionSetTags": [],
          "trustDetail": {
            "id": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
            "name": "CCC"
          },
          "isFavourite": false,
          "canDelete": true
        },
        {
          "id": "28e4bc00-2244-4268-8be1-fefd4566e0a8",
          "name": "Copy_Health follow up111",
          "status": "approved",
          "updatedAt": "2022-10-19T11:25:47.682Z",
          "copyRight": false,
          "category": "b6a4a5c7-9b9d-4349-8a26-e493b130277f",
          "tags": [],
          "ageRange": "bcbf6ea6-a44c-4655-a32c-f0e4617070d7",
          "createdBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
          "public": true,
          "questionSetCategory": {
            "name": "Patient measure",
            "id": "b6a4a5c7-9b9d-4349-8a26-e493b130277f"
          },
          "questionSetAgeRange": {
            "name": "Paediatric"
          },
          "questionShared": [],
          "userFavourite": [
            {
              "id": "018510a9-4c99-469b-8c48-ce373812e079",
              "questionSetId": "28e4bc00-2244-4268-8be1-fefd4566e0a8",
              "userId": "09ac1ccd-92db-41a0-93e2-a700bd75aa8c",
              "createdAt": "2022-10-20T11:11:51.352Z",
              "updatedAt": "2022-10-20T11:11:51.353Z"
            }
          ],
          "questionSetTags": [],
          "trustDetail": {
            "id": "1bd3ca66-2016-4f99-9ec8-d08ceb165df4",
            "name": "North Bristol NHS Trust"
          },
          "isFavourite": false,
          "canDelete": true
        },
        {
          "id": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
          "name": "Test the likert flow",
          "status": "approved",
          "updatedAt": "2022-10-19T10:55:25.576Z",
          "copyRight": false,
          "category": "786d7d03-d12b-4dc2-b66f-be7ae5bd3541",
          "tags": [],
          "ageRange": "a8588616-95e1-4a4f-a4fd-68ac4ffe6dea",
          "createdBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
          "public": true,
          "questionSetCategory": {
            "name": "Covid",
            "id": "786d7d03-d12b-4dc2-b66f-be7ae5bd3541"
          },
          "questionSetAgeRange": {
            "name": "sample test"
          },
          "questionShared": [
            {
              "id": "459ff2fd-c700-43fa-8534-f890b26173eb",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "ed9141fd-8a55-44e5-bf29-1fb1dc3874d1",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": "2022-08-29T09:15:00.000Z",
              "dueDate": "2022-08-29T09:15:00.000Z",
              "responseViewNotifyToPatient": false,
              "shareType": "scheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": null,
              "createdAt": "2022-08-29T09:15:02.582Z",
              "updatedAt": "2022-08-29T09:15:02.582Z"
            },
            {
              "id": "39620e3e-bbf4-4afd-9098-d07f57a77caa",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "266914ec-74fa-4e51-830d-36af14f369c9",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": "2022-08-29T09:15:00.000Z",
              "dueDate": "2022-08-29T09:15:00.000Z",
              "responseViewNotifyToPatient": false,
              "shareType": "scheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": null,
              "createdAt": "2022-08-29T09:15:02.585Z",
              "updatedAt": "2022-08-29T09:15:02.585Z"
            },
            {
              "id": "b5746e77-01bd-4c2c-9ae7-748d1a1b003e",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "ffa95b26-792e-4a16-98b9-263d217603fa",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": "2022-08-29T09:15:00.000Z",
              "dueDate": "2022-08-29T09:15:00.000Z",
              "responseViewNotifyToPatient": false,
              "shareType": "scheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": null,
              "createdAt": "2022-08-29T09:15:02.586Z",
              "updatedAt": "2022-08-29T09:15:02.587Z"
            },
            {
              "id": "ade39946-baae-44a7-b8ce-6ea220089de6",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "28650c90-7e0b-49d6-b34d-3f40a5c49707",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": null,
              "dueDate": null,
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
              "createdAt": "2022-08-29T09:25:51.110Z",
              "updatedAt": "2022-08-29T09:25:51.111Z"
            },
            {
              "id": "49aea417-0ae7-4c66-bff9-7d2b2b85243b",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "c5e6232e-ea38-400e-9daf-ee5cfcd82f62",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": null,
              "dueDate": null,
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
              "createdAt": "2022-08-29T09:25:51.126Z",
              "updatedAt": "2022-08-29T09:25:51.127Z"
            },
            {
              "id": "2a2b1cc1-dca8-44e6-85b8-dcdaef0abed5",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "ffa95b26-792e-4a16-98b9-263d217603fa",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": "2022-09-01T06:40:00.000Z",
              "dueDate": "2022-09-01T06:40:00.000Z",
              "responseViewNotifyToPatient": false,
              "shareType": "scheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": null,
              "createdAt": "2022-09-02T10:15:03.394Z",
              "updatedAt": "2022-09-02T10:15:03.395Z"
            },
            {
              "id": "b3de4b1b-a4f2-495c-8369-968b3cf106ce",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "ffa95b26-792e-4a16-98b9-263d217603fa",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": "2022-09-01T06:40:00.000Z",
              "dueDate": "2022-09-01T06:40:00.000Z",
              "responseViewNotifyToPatient": false,
              "shareType": "scheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": null,
              "createdAt": "2022-09-02T10:15:03.786Z",
              "updatedAt": "2022-09-02T10:15:03.786Z"
            },
            {
              "id": "8d20c043-84d4-47f1-a79a-eae6d0e59dc3",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "ffa95b26-792e-4a16-98b9-263d217603fa",
              "status": "completed",
              "completedDate": "2022-08-29T08:26:07.986Z",
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [
                "1d"
              ],
              "reminderTypeDates": [
                "2022-09-02T06:34:28.000Z"
              ],
              "reminderOn": null,
              "dueDate": "2022-09-03T06:34:28.000Z",
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": [
                "62dfd70c3984a0196afbafc4",
                "62dfd6963984a0196afbaf1a",
                "62dfd5b93984a0196afbae87",
                "62dfd8d03984a0196afbb105",
                "62dfda7d3984a0196afbb1f5",
                "62f25f78ef3bf4bade4996fc",
                "62ff54bff428736285dd257a",
                "6315d26523cfc774f78f645e"
              ],
              "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
              "createdAt": "2022-08-29T06:37:16.650Z",
              "updatedAt": "2022-09-10T07:14:05.155Z"
            },
            {
              "id": "e355f103-916b-4caa-998d-3d859c8e0a32",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "ffa95b26-792e-4a16-98b9-263d217603fa",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": null,
              "dueDate": null,
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
              "createdAt": "2022-09-13T13:52:46.720Z",
              "updatedAt": "2022-09-13T13:52:46.720Z"
            },
            {
              "id": "bb1ef37c-a7f6-4d6c-8ee7-a121b5aafea3",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "ffa95b26-792e-4a16-98b9-263d217603fa",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": null,
              "dueDate": null,
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
              "createdAt": "2022-09-13T15:05:30.879Z",
              "updatedAt": "2022-09-13T15:05:30.880Z"
            },
            {
              "id": "b3e26f05-d937-4ead-bbfd-34e6c769114d",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "ae03ba8a-c0a7-42fc-a6ce-87fc87331356",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": null,
              "dueDate": null,
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
              "createdAt": "2022-09-13T16:15:57.667Z",
              "updatedAt": "2022-09-13T16:15:57.668Z"
            },
            {
              "id": "84aeab65-4e98-4944-9a51-0d32baafee7c",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "37267fdb-ceb4-4315-afc7-c13555dba97f",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": null,
              "dueDate": null,
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
              "createdAt": "2022-09-13T16:34:12.519Z",
              "updatedAt": "2022-09-13T16:34:12.520Z"
            },
            {
              "id": "3229ccea-b5fe-4eca-95e8-439347dd2bd9",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "a40e2f65-fd2f-4fd8-a0f6-651b1182e380",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": null,
              "dueDate": null,
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
              "createdAt": "2022-09-13T16:34:48.673Z",
              "updatedAt": "2022-09-13T16:34:48.674Z"
            },
            {
              "id": "c58a9ae2-c04c-4c3d-92a7-19acaff4b0d8",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "266914ec-74fa-4e51-830d-36af14f369c9",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": null,
              "dueDate": null,
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
              "createdAt": "2022-09-13T16:34:48.675Z",
              "updatedAt": "2022-09-13T16:34:48.675Z"
            },
            {
              "id": "fbbc6156-e405-41dd-ab45-57f52cefe07a",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "021e2203-e2f2-4021-a001-62bd924dc280",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": null,
              "dueDate": null,
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
              "createdAt": "2022-09-13T16:34:48.675Z",
              "updatedAt": "2022-09-13T16:34:48.675Z"
            },
            {
              "id": "86043472-a58d-4889-bb07-3430bd3cf33a",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "565e6259-f52a-40b5-b76c-10171b8ec9f1",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": null,
              "dueDate": null,
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
              "createdAt": "2022-09-13T16:34:48.676Z",
              "updatedAt": "2022-09-13T16:34:48.676Z"
            },
            {
              "id": "819364c4-e824-49d6-893c-4e1cef7df1a2",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "2466eec8-113c-44d6-aa98-eab5f8377271",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": null,
              "dueDate": null,
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
              "createdAt": "2022-09-13T16:48:53.602Z",
              "updatedAt": "2022-09-13T16:48:53.602Z"
            },
            {
              "id": "8c1332c8-ffe4-4988-bd51-c0bb984e186c",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "2e8ef467-ed0c-4e89-8ca7-b0826fdd4d41",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": null,
              "dueDate": null,
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
              "createdAt": "2022-09-13T16:53:11.581Z",
              "updatedAt": "2022-09-13T16:53:11.582Z"
            },
            {
              "id": "1b386538-fca7-4a51-837a-a8db98da8999",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "266914ec-74fa-4e51-830d-36af14f369c9",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": null,
              "dueDate": null,
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
              "createdAt": "2022-09-13T16:54:35.137Z",
              "updatedAt": "2022-09-13T16:54:35.137Z"
            },
            {
              "id": "9a7212d7-d8da-411c-9d2b-d7c9d264865a",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "2e726d7f-ff9b-47f7-9bb0-591b4e163b87",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": null,
              "dueDate": null,
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
              "createdAt": "2022-09-13T16:54:35.139Z",
              "updatedAt": "2022-09-13T16:54:35.139Z"
            },
            {
              "id": "1d980099-2809-4b60-81a1-f04fa81667a8",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "ed9141fd-8a55-44e5-bf29-1fb1dc3874d1",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": null,
              "dueDate": null,
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
              "createdAt": "2022-09-13T16:54:35.140Z",
              "updatedAt": "2022-09-13T16:54:35.140Z"
            },
            {
              "id": "eed2a742-f492-4ba8-bbb9-8b81ce731ab6",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "497b0df1-903a-4663-b5cb-2f95b31202be",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": "2022-09-13T17:51:00.000Z",
              "dueDate": "2022-09-13T17:51:00.000Z",
              "responseViewNotifyToPatient": false,
              "shareType": "scheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": null,
              "createdAt": "2022-09-13T17:51:02.123Z",
              "updatedAt": "2022-09-13T17:51:02.123Z"
            },
            {
              "id": "39be0a8c-1d78-4f96-99a7-8c4b00df2253",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "48ca507c-4ee4-49b5-a272-69a2bc1eaa5e",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": "2022-09-13T17:51:00.000Z",
              "dueDate": "2022-09-13T17:51:00.000Z",
              "responseViewNotifyToPatient": false,
              "shareType": "scheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": null,
              "createdAt": "2022-09-13T17:51:02.127Z",
              "updatedAt": "2022-09-13T17:51:02.128Z"
            },
            {
              "id": "2f08fe99-856f-4547-9cfe-7a2a2a989efb",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "28650c90-7e0b-49d6-b34d-3f40a5c49707",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": null,
              "dueDate": null,
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
              "createdAt": "2022-09-13T17:59:24.395Z",
              "updatedAt": "2022-09-13T17:59:24.395Z"
            },
            {
              "id": "043d96af-5bbd-4e4d-8141-a412907dac0c",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "816b8dbd-6f55-4af1-b2a2-f689377025d0",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": null,
              "dueDate": null,
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
              "createdAt": "2022-09-14T15:59:47.776Z",
              "updatedAt": "2022-09-14T15:59:47.777Z"
            },
            {
              "id": "ca3ed802-f38e-46e4-9de4-81e06474c941",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "ffa95b26-792e-4a16-98b9-263d217603fa",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": null,
              "dueDate": null,
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
              "createdAt": "2022-09-14T15:59:47.778Z",
              "updatedAt": "2022-09-14T15:59:47.778Z"
            },
            {
              "id": "59c76788-7336-4f03-a5e2-ed020f4fd5a6",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "2e726d7f-ff9b-47f7-9bb0-591b4e163b87",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": null,
              "dueDate": null,
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
              "createdAt": "2022-09-14T15:59:47.785Z",
              "updatedAt": "2022-09-14T15:59:47.785Z"
            },
            {
              "id": "c1da0bdc-d53d-437c-98a6-72b8fbee56b7",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "a40e2f65-fd2f-4fd8-a0f6-651b1182e380",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": "2022-09-14T17:00:00.000Z",
              "dueDate": "2022-09-14T17:00:00.000Z",
              "responseViewNotifyToPatient": false,
              "shareType": "scheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": null,
              "createdAt": "2022-09-14T17:00:02.930Z",
              "updatedAt": "2022-09-14T17:00:02.936Z"
            },
            {
              "id": "b923fe4d-6fbe-440b-8d73-d4de1f469a61",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "021e2203-e2f2-4021-a001-62bd924dc280",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": "2022-09-14T17:00:00.000Z",
              "dueDate": "2022-09-14T17:00:00.000Z",
              "responseViewNotifyToPatient": false,
              "shareType": "scheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": null,
              "createdAt": "2022-09-14T17:00:02.938Z",
              "updatedAt": "2022-09-14T17:00:02.938Z"
            },
            {
              "id": "592906bd-e034-49c8-b9c8-d88e6965d0d8",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "8ec60ea3-f64a-4b89-9fb6-cb21db26b560",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": true,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": null,
              "dueDate": null,
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
              "createdAt": "2022-09-13T13:52:46.718Z",
              "updatedAt": "2022-10-19T05:33:48.242Z"
            },
            {
              "id": "b74a1f66-d30f-4d23-b71e-f79da06d893d",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "565e6259-f52a-40b5-b76c-10171b8ec9f1",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": "2022-09-14T17:00:00.000Z",
              "dueDate": "2022-09-14T17:00:00.000Z",
              "responseViewNotifyToPatient": false,
              "shareType": "scheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": null,
              "createdAt": "2022-09-14T17:00:02.939Z",
              "updatedAt": "2022-09-14T17:00:02.939Z"
            },
            {
              "id": "286599c4-c27c-4bbd-ba79-42d273e39527",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "4a6d4c73-1d5b-4bec-b6c1-ba3629ce1aa0",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": "2022-09-14T18:30:00.000Z",
              "dueDate": "2022-09-14T18:30:00.000Z",
              "responseViewNotifyToPatient": false,
              "shareType": "scheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": null,
              "createdAt": "2022-09-14T18:30:03.064Z",
              "updatedAt": "2022-09-14T18:30:03.064Z"
            },
            {
              "id": "123eccc5-05cf-4ae8-98ac-4bb4622a080c",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "b1a2b097-f533-4fb4-b888-bd0960212023",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": "2022-09-14T18:30:00.000Z",
              "dueDate": "2022-09-14T18:30:00.000Z",
              "responseViewNotifyToPatient": false,
              "shareType": "scheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": null,
              "createdAt": "2022-09-14T18:30:03.066Z",
              "updatedAt": "2022-09-14T18:30:03.066Z"
            },
            {
              "id": "8b986240-55b6-40d6-b2b5-614255b21788",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "8ec60ea3-f64a-4b89-9fb6-cb21db26b560",
              "status": "completed",
              "completedDate": "2022-10-19T05:33:52.118Z",
              "responseViewBySender": null,
              "questionnaireViewByReceiver": true,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": null,
              "dueDate": null,
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
              "createdAt": "2022-10-19T05:33:39.513Z",
              "updatedAt": "2022-10-19T05:33:52.202Z"
            },
            {
              "id": "5469fff4-c45b-4f36-8f7c-6a5111d77f54",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "ffa95b26-792e-4a16-98b9-263d217603fa",
              "status": "completed",
              "completedDate": "2022-08-18T10:25:04.602Z",
              "responseViewBySender": null,
              "questionnaireViewByReceiver": true,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": null,
              "dueDate": null,
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": [
                "62f49d64af692fd253541ac9",
                "62f497bbaf692fd253541a10",
                "62f496adaf692fd253541991",
                "62f495e2af692fd253541914",
                "62f49482af692fd25354184e",
                "62f493e4af692fd2535417db",
                "62f492e3af692fd253541756",
                "62f3bf2357effe974bf0abe1",
                "62d654be8f0ff2b258765d97"
              ],
              "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
              "createdAt": "2022-08-18T10:24:29.181Z",
              "updatedAt": "2022-08-18T11:00:04.785Z"
            },
            {
              "id": "fd3fe4ba-156d-42ca-be7d-25cff8cefc11",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "8ec60ea3-f64a-4b89-9fb6-cb21db26b560",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": null,
              "dueDate": null,
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
              "createdAt": "2022-10-19T11:00:21.638Z",
              "updatedAt": "2022-10-19T11:00:21.638Z"
            },
            {
              "id": "69b062eb-93d2-47dd-8796-2457e9c12a8d",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "ffa95b26-792e-4a16-98b9-263d217603fa",
              "status": "completed",
              "completedDate": "2022-08-29T08:25:41.324Z",
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [
                "1d"
              ],
              "reminderTypeDates": [
                "2022-08-30T18:30:00.000Z"
              ],
              "reminderOn": null,
              "dueDate": "2022-08-31T18:30:00.000Z",
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
              "createdAt": "2022-08-29T06:38:04.909Z",
              "updatedAt": "2022-08-29T08:25:41.505Z"
            },
            {
              "id": "ebc773d9-fb6c-4270-a321-9aa9f6a3a234",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "816b8dbd-6f55-4af1-b2a2-f689377025d0",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": "2022-10-06T06:48:37.000Z",
              "dueDate": "2022-08-29T09:15:00.000Z",
              "responseViewNotifyToPatient": false,
              "shareType": "scheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": null,
              "createdAt": "2022-08-29T09:15:02.577Z",
              "updatedAt": "2022-10-06T06:48:37.035Z"
            },
            {
              "id": "8290589c-67fd-4ab7-b216-7eb2aae621b4",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "ffa95b26-792e-4a16-98b9-263d217603fa",
              "status": "draft",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": null,
              "dueDate": null,
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
              "createdAt": "2022-08-23T10:02:35.629Z",
              "updatedAt": "2022-08-29T08:26:58.891Z"
            },
            {
              "id": "f1ee376f-fef9-427d-b5ca-d3d7b46f9137",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "sharedBy": "54c7149a-2fdb-411a-99f8-f929705b12d2",
              "sharedTo": "2e726d7f-ff9b-47f7-9bb0-591b4e163b87",
              "status": "shared",
              "completedDate": null,
              "responseViewBySender": null,
              "questionnaireViewByReceiver": null,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": "2022-08-29T09:15:00.000Z",
              "dueDate": "2022-08-29T09:15:00.000Z",
              "responseViewNotifyToPatient": false,
              "shareType": "scheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": null,
              "createdAt": "2022-08-29T09:15:02.580Z",
              "updatedAt": "2022-08-29T09:15:02.581Z"
            }
          ],
          "userFavourite": [
            {
              "id": "7a264f76-df18-40c0-9973-b6e16d26a665",
              "questionSetId": "b0cb57a7-c5dc-4b58-bbc3-9f1b81d1e889",
              "userId": "09ac1ccd-92db-41a0-93e2-a700bd75aa8c",
              "createdAt": "2022-10-20T11:12:15.450Z",
              "updatedAt": "2022-10-20T11:12:15.450Z"
            }
          ],
          "questionSetTags": [],
          "trustDetail": {
            "id": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
            "name": "CCC"
          },
          "isFavourite": false,
          "canDelete": false
        },
        {
          "id": "f8e67b45-a661-4631-b3d9-fb1f6c219ac3",
          "name": "MSI43",
          "status": "approved",
          "updatedAt": "2022-10-17T10:12:05.683Z",
          "copyRight": false,
          "category": "b22262fa-25ca-425f-9852-ed110ea6f934",
          "tags": [
            "84c4cefa-4814-4e90-818d-77e23acc42c0"
          ],
          "ageRange": "23f66658-3472-4841-8e2d-66f8f0bc1386",
          "createdBy": "c2a79443-a414-4d05-a49f-29bfdf90653b",
          "public": true,
          "questionSetCategory": {
            "name": "Patient research form",
            "id": "b22262fa-25ca-425f-9852-ed110ea6f934"
          },
          "questionSetAgeRange": {
            "name": "Geriatric"
          },
          "questionShared": [
            {
              "id": "6f556b7e-2627-4d2e-89a0-d5608952bda7",
              "questionSetId": "f8e67b45-a661-4631-b3d9-fb1f6c219ac3",
              "sharedBy": "c2a79443-a414-4d05-a49f-29bfdf90653b",
              "sharedTo": "d847c6b5-9ae2-47c6-88b9-5c2f0cd57d84",
              "status": "completed",
              "completedDate": "2022-10-17T10:15:05.243Z",
              "responseViewBySender": null,
              "questionnaireViewByReceiver": true,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": null,
              "dueDate": null,
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": [
                "62dfda7d3984a0196afbb1f5",
                "62dfd6963984a0196afbaf1a",
                "62dfd70c3984a0196afbafc4"
              ],
              "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
              "createdAt": "2022-10-17T10:13:36.398Z",
              "updatedAt": "2022-10-17T10:16:35.283Z"
            },
            {
              "id": "8230abee-be97-47d8-9287-667b43574672",
              "questionSetId": "f8e67b45-a661-4631-b3d9-fb1f6c219ac3",
              "sharedBy": "09ac1ccd-92db-41a0-93e2-a700bd75aa8c",
              "sharedTo": "8ec60ea3-f64a-4b89-9fb6-cb21db26b560",
              "status": "completed",
              "completedDate": "2022-10-19T05:24:17.126Z",
              "responseViewBySender": null,
              "questionnaireViewByReceiver": true,
              "reminderTypes": [],
              "reminderTypeDates": [],
              "reminderOn": null,
              "dueDate": null,
              "responseViewNotifyToPatient": false,
              "shareType": "unscheduled",
              "appointmentId": null,
              "resourceIds": null,
              "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
              "createdAt": "2022-10-19T05:21:47.176Z",
              "updatedAt": "2022-10-19T05:24:17.353Z"
            }
          ],
          "userFavourite": [],
          "questionSetTags": [
            {
              "id": "5be31fd9-c881-4f80-99f9-a5c60d53b878",
              "tagsInfo": {
                "name": "Covid",
                "id": "84c4cefa-4814-4e90-818d-77e23acc42c0"
              }
            }
          ],
          "trustDetail": {
            "id": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
            "name": "CCC"
          },
          "isFavourite": false,
          "canDelete": false
        }
      ],
      "publicCount": 74,
      "draftForms": [
        {
          "id": "be220a2a-eb60-45a0-9ee8-c0dcf9600adb",
          "name": "msi12345",
          "status": "draft",
          "updatedAt": "2022-11-02T11:00:16.825Z",
          "copyRight": false,
          "category": "b22262fa-25ca-425f-9852-ed110ea6f934",
          "tags": [
            "3a2224fc-028e-4993-8d08-20b8a5dc820e"
          ],
          "ageRange": "bcbf6ea6-a44c-4655-a32c-f0e4617070d7",
          "createdBy": "c2a79443-a414-4d05-a49f-29bfdf90653b",
          "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
          "public": false,
          "questionSetCategory": {
            "name": "Patient research form",
            "id": "b22262fa-25ca-425f-9852-ed110ea6f934"
          },
          "questionSetAgeRange": {
            "name": "Paediatric"
          },
          "trustDetail": {
            "id": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
            "name": "CCC"
          },
          "userFavourite": [],
          "questionShared": [],
          "isFavourite": false,
          "canDelete": true
        },
        {
          "id": "d601153b-acc9-4856-84c9-e9c2c6ac277e",
          "name": "MSI1510",
          "status": "draft",
          "updatedAt": "2022-11-01T05:51:04.734Z",
          "copyRight": false,
          "category": "276e3bea-d829-4af6-b2dc-5a97cc5a4221",
          "tags": [],
          "ageRange": "bcbf6ea6-a44c-4655-a32c-f0e4617070d7",
          "createdBy": "c2a79443-a414-4d05-a49f-29bfdf90653b",
          "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
          "public": false,
          "questionSetCategory": {
            "name": "Feedback form",
            "id": "276e3bea-d829-4af6-b2dc-5a97cc5a4221"
          },
          "questionSetAgeRange": {
            "name": "Paediatric"
          },
          "trustDetail": {
            "id": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
            "name": "CCC"
          },
          "userFavourite": [],
          "questionShared": [],
          "isFavourite": false,
          "canDelete": true
        },
        {
          "id": "a5c29c74-5071-4ec5-b44f-caa4b01f6f8e",
          "name": "xxxaxa",
          "status": "draft",
          "updatedAt": "2022-11-01T04:50:07.297Z",
          "copyRight": false,
          "category": "b22262fa-25ca-425f-9852-ed110ea6f934",
          "tags": [
            "3a2224fc-028e-4993-8d08-20b8a5dc820e"
          ],
          "ageRange": "bcbf6ea6-a44c-4655-a32c-f0e4617070d7",
          "createdBy": "c2a79443-a414-4d05-a49f-29bfdf90653b",
          "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
          "public": false,
          "questionSetCategory": {
            "name": "Patient research form",
            "id": "b22262fa-25ca-425f-9852-ed110ea6f934"
          },
          "questionSetAgeRange": {
            "name": "Paediatric"
          },
          "trustDetail": {
            "id": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
            "name": "CCC"
          },
          "userFavourite": [],
          "questionShared": [],
          "isFavourite": false,
          "canDelete": true
        },
        {
          "id": "a09ebd8d-c700-4ddf-b0d6-661802dce49f",
          "name": "Test21",
          "status": "draft",
          "updatedAt": "2022-10-14T08:04:34.940Z",
          "copyRight": false,
          "category": "276e3bea-d829-4af6-b2dc-5a97cc5a4221",
          "tags": [
            "c7ed0972-b771-4af4-8cf6-dbcfa8c59e64"
          ],
          "ageRange": "a8588616-95e1-4a4f-a4fd-68ac4ffe6dea",
          "createdBy": "c2a79443-a414-4d05-a49f-29bfdf90653b",
          "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
          "public": false,
          "questionSetCategory": {
            "name": "Feedback form",
            "id": "276e3bea-d829-4af6-b2dc-5a97cc5a4221"
          },
          "questionSetAgeRange": {
            "name": "sample test"
          },
          "trustDetail": {
            "id": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
            "name": "CCC"
          },
          "userFavourite": [],
          "questionShared": [],
          "isFavourite": false,
          "canDelete": true
        },
        {
          "id": "1107aab1-f619-48fd-b090-b05a8ac56f48",
          "name": "MSI234",
          "status": "draft",
          "updatedAt": "2022-10-13T09:16:58.154Z",
          "copyRight": false,
          "category": "b22262fa-25ca-425f-9852-ed110ea6f934",
          "tags": [
            "3a2224fc-028e-4993-8d08-20b8a5dc820e"
          ],
          "ageRange": "bc14fc66-15a8-43da-82eb-32767380d198",
          "createdBy": "c2a79443-a414-4d05-a49f-29bfdf90653b",
          "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
          "public": false,
          "questionSetCategory": {
            "name": "Patient research form",
            "id": "b22262fa-25ca-425f-9852-ed110ea6f934"
          },
          "questionSetAgeRange": {
            "name": "Test"
          },
          "trustDetail": {
            "id": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
            "name": "CCC"
          },
          "userFavourite": [],
          "questionShared": [],
          "isFavourite": false,
          "canDelete": true
        },
        {
          "id": "f07084c3-f104-41ba-9b89-649993698c27",
          "name": "sCopy_Test form",
          "status": "draft",
          "updatedAt": "2022-10-03T08:31:57.695Z",
          "copyRight": false,
          "category": "276e3bea-d829-4af6-b2dc-5a97cc5a4221",
          "tags": [],
          "ageRange": "bcbf6ea6-a44c-4655-a32c-f0e4617070d7",
          "createdBy": "c2a79443-a414-4d05-a49f-29bfdf90653b",
          "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
          "public": false,
          "questionSetCategory": {
            "name": "Feedback form",
            "id": "276e3bea-d829-4af6-b2dc-5a97cc5a4221"
          },
          "questionSetAgeRange": {
            "name": "Paediatric"
          },
          "trustDetail": {
            "id": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
            "name": "CCC"
          },
          "userFavourite": [],
          "questionShared": [],
          "isFavourite": false,
          "canDelete": true
        }
      ],
      "draftCount": 25,
      "formsApprovedCount": 23,
      "formsSharedCount": 75,
      "needApprovalCount": 21,
      "formsSubmissionCount": 21
    },
    "msg": null
  }

  export const questionData= {
    "status": "success",
    "result": {
      "id": "70856eb6-75f7-4e59-afba-c3e999f34dcc",
      "name": "Covid 70",
      "description": null,
      "status": "approved",
      "approved": "true",
      "approvedBy": "0685583e-7b47-4713-8923-8098154544a2",
      "createdBy": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
      "pageNumber": false,
      "templateFrom": null,
      "category": "4118e383-551b-4f21-b8bf-743edccdebe4",
      "ageRange": "a48c6f1d-66af-455b-b9fa-0224d8f8de46",
      "copyRight": false,
      "tags": [],
      "public": false,
      "questionSetAuthor": {
        "id": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
        "firstName": "jayanthi",
        "lastName": "R",
        "email": "jayanthi+test2@mindwaveventures.com"
      },
      "questions": [
        {
          "id": "24cff0af-3356-49da-ab6c-5ee46ce9a702",
          "question": "multiple question",
          "description": null,
          "hasDescription": false,
          "hasConditionalQuestion": false,
          "hasImage": false,
          "imageUrl": null,
          "type": "multiplechoice",
          "isMandatory": false,
          "dependentQuestionId": null,
          "dependentOptionId": null,
          "sortOrder": 0
        },
        {
          "id": "5c4f8808-8914-4bfb-9721-63fa7422408b",
          "question": "enter text hello",
          "description": null,
          "hasDescription": false,
          "hasConditionalQuestion": false,
          "hasImage": false,
          "imageUrl": null,
          "type": "freetext",
          "isMandatory": false,
          "dependentQuestionId": null,
          "dependentOptionId": null,
          "sortOrder": 1
        },
        {
          "id": "90c034cc-3a59-4ee6-9076-d63c95d660a8",
          "question": "radio button",
          "description": null,
          "hasDescription": false,
          "hasConditionalQuestion": false,
          "hasImage": false,
          "imageUrl": null,
          "type": "yesnoquestion",
          "isMandatory": false,
          "dependentQuestionId": null,
          "dependentOptionId": null,
          "sortOrder": 2
        },
        {
          "id": "36561ae1-9485-43ea-9b90-0be762c09163",
          "question": "enter statement  list ",
          "description": null,
          "hasDescription": false,
          "hasConditionalQuestion": false,
          "hasImage": false,
          "imageUrl": null,
          "type": "statement",
          "isMandatory": false,
          "dependentQuestionId": null,
          "dependentOptionId": null,
          "sortOrder": 3
        },
        {
          "id": "9fcaf7e7-fc1b-4282-948f-d37ce84935d2",
          "question": "dropdown list",
          "description": null,
          "hasDescription": false,
          "hasConditionalQuestion": false,
          "hasImage": false,
          "imageUrl": null,
          "type": "dropdown",
          "isMandatory": false,
          "dependentQuestionId": null,
          "dependentOptionId": null,
          "sortOrder": 4
        },
        {
          "id": "4f3063e3-fe43-4030-ace2-6d80e8b589f2",
          "question": "section area",
          "description": null,
          "hasDescription": false,
          "hasConditionalQuestion": false,
          "hasImage": false,
          "imageUrl": null,
          "type": "section",
          "isMandatory": false,
          "dependentQuestionId": null,
          "dependentOptionId": null,
          "sortOrder": 5
        },
        {
          "id": "fa4043ec-545c-432a-828b-8867f9dce5e8",
          "question": "likert list question",
          "description": null,
          "hasDescription": false,
          "hasConditionalQuestion": false,
          "hasImage": false,
          "imageUrl": null,
          "type": "likert",
          "isMandatory": false,
          "dependentQuestionId": null,
          "dependentOptionId": null,
          "sortOrder": 6
        }
      ],
      "questionSetCategory": {
        "name": "Patient assessment",
        "id": "4118e383-551b-4f21-b8bf-743edccdebe4"
      },
      "questionSetAgeRange": {
        "name": "age"
      },
      "userFavourite": [
        {
          "id": "708d984e-8041-470c-af41-c133b75a72e0",
          "questionSetId": "70856eb6-75f7-4e59-afba-c3e999f34dcc",
          "userId": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
          "createdAt": "2022-09-27T13:30:38.442Z",
          "updatedAt": "2022-09-27T13:30:38.442Z"
        }
      ],
      "questionShared": [
        {
          "id": "9573cb59-0588-4ba5-b013-92b3174599d2",
          "questionSetId": "70856eb6-75f7-4e59-afba-c3e999f34dcc",
          "sharedBy": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
          "sharedTo": "1fbe8a14-3ed7-426e-b84c-5afe6e4150f7",
          "status": "shared",
          "completedDate": null,
          "responseViewBySender": null,
          "questionnaireViewByReceiver": null,
          "reminderTypes": [],
          "reminderTypeDates": [],
          "reminderOn": null,
          "dueDate": null,
          "responseViewNotifyToPatient": false,
          "shareType": "unscheduled",
          "appointmentId": null,
          "resourceIds": null,
          "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
          "createdAt": "2022-09-30T09:55:21.136Z",
          "updatedAt": "2022-09-30T09:55:21.138Z"
        },
        {
          "id": "b9bb8c11-c5d2-4a55-a32c-2a8b2e097ec3",
          "questionSetId": "70856eb6-75f7-4e59-afba-c3e999f34dcc",
          "sharedBy": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
          "sharedTo": "1fbe8a14-3ed7-426e-b84c-5afe6e4150f7",
          "status": "completed",
          "completedDate": "2022-09-30T09:52:32.793Z",
          "responseViewBySender": null,
          "questionnaireViewByReceiver": true,
          "reminderTypes": [],
          "reminderTypeDates": [],
          "reminderOn": null,
          "dueDate": null,
          "responseViewNotifyToPatient": false,
          "shareType": "unscheduled",
          "appointmentId": null,
          "resourceIds": null,
          "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
          "createdAt": "2022-09-30T06:43:29.363Z",
          "updatedAt": "2022-09-30T09:52:32.915Z"
        },
        {
          "id": "a3ed2f13-e050-493d-8790-b3ac66d1f2c4",
          "questionSetId": "70856eb6-75f7-4e59-afba-c3e999f34dcc",
          "sharedBy": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
          "sharedTo": "1fbe8a14-3ed7-426e-b84c-5afe6e4150f7",
          "status": "shared",
          "completedDate": null,
          "responseViewBySender": null,
          "questionnaireViewByReceiver": null,
          "reminderTypes": [],
          "reminderTypeDates": [],
          "reminderOn": null,
          "dueDate": "2022-10-25T11:39:11.000Z",
          "responseViewNotifyToPatient": false,
          "shareType": "unscheduled",
          "appointmentId": null,
          "resourceIds": null,
          "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
          "createdAt": "2022-10-20T11:39:25.106Z",
          "updatedAt": "2022-10-20T11:39:25.106Z"
        },
        {
          "id": "11856843-5eb6-45ac-9958-42d4db45abd3",
          "questionSetId": "70856eb6-75f7-4e59-afba-c3e999f34dcc",
          "sharedBy": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
          "sharedTo": "68fb5124-b4a9-4777-8752-ed8671fedf9f",
          "status": "shared",
          "completedDate": null,
          "responseViewBySender": null,
          "questionnaireViewByReceiver": null,
          "reminderTypes": [],
          "reminderTypeDates": [],
          "reminderOn": null,
          "dueDate": null,
          "responseViewNotifyToPatient": false,
          "shareType": "unscheduled",
          "appointmentId": null,
          "resourceIds": null,
          "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
          "createdAt": "2022-09-22T10:49:14.534Z",
          "updatedAt": "2022-09-22T10:49:14.534Z"
        },
        {
          "id": "d0866a9c-f02e-49aa-b341-7777211ca523",
          "questionSetId": "70856eb6-75f7-4e59-afba-c3e999f34dcc",
          "sharedBy": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
          "sharedTo": "1fbe8a14-3ed7-426e-b84c-5afe6e4150f7",
          "status": "shared",
          "completedDate": null,
          "responseViewBySender": null,
          "questionnaireViewByReceiver": null,
          "reminderTypes": [],
          "reminderTypeDates": [],
          "reminderOn": "2022-10-06T08:31:00.000Z",
          "dueDate": "2022-10-06T08:31:00.000Z",
          "responseViewNotifyToPatient": false,
          "shareType": "scheduled",
          "appointmentId": null,
          "resourceIds": null,
          "tpId": null,
          "createdAt": "2022-10-06T08:31:02.975Z",
          "updatedAt": "2022-10-06T08:31:02.976Z"
        },
        {
          "id": "e6525482-4a04-445e-83af-f35577bd00ec",
          "questionSetId": "70856eb6-75f7-4e59-afba-c3e999f34dcc",
          "sharedBy": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
          "sharedTo": "ee3e298a-4ca9-4d40-91bd-c8667be4c3ed",
          "status": "shared",
          "completedDate": null,
          "responseViewBySender": null,
          "questionnaireViewByReceiver": null,
          "reminderTypes": [],
          "reminderTypeDates": [],
          "reminderOn": null,
          "dueDate": "2022-10-29T18:30:00.000Z",
          "responseViewNotifyToPatient": false,
          "shareType": "unscheduled",
          "appointmentId": null,
          "resourceIds": null,
          "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
          "createdAt": "2022-10-20T11:48:21.799Z",
          "updatedAt": "2022-10-20T11:48:21.799Z"
        },
        {
          "id": "145dca6b-ee47-4410-8a0f-7308b9724280",
          "questionSetId": "70856eb6-75f7-4e59-afba-c3e999f34dcc",
          "sharedBy": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
          "sharedTo": "1fbe8a14-3ed7-426e-b84c-5afe6e4150f7",
          "status": "shared",
          "completedDate": null,
          "responseViewBySender": null,
          "questionnaireViewByReceiver": null,
          "reminderTypes": [],
          "reminderTypeDates": [],
          "reminderOn": null,
          "dueDate": null,
          "responseViewNotifyToPatient": false,
          "shareType": "unscheduled",
          "appointmentId": null,
          "resourceIds": null,
          "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
          "createdAt": "2022-09-30T09:55:14.302Z",
          "updatedAt": "2022-09-30T09:55:14.302Z"
        },
        {
          "id": "bb175ea5-8a0e-4cee-afad-74be230147b3",
          "questionSetId": "70856eb6-75f7-4e59-afba-c3e999f34dcc",
          "sharedBy": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
          "sharedTo": "250b3425-3dd5-43e9-8e99-e7fa5e9bb241",
          "status": "shared",
          "completedDate": null,
          "responseViewBySender": null,
          "questionnaireViewByReceiver": null,
          "reminderTypes": [],
          "reminderTypeDates": [],
          "reminderOn": null,
          "dueDate": null,
          "responseViewNotifyToPatient": false,
          "shareType": "unscheduled",
          "appointmentId": null,
          "resourceIds": null,
          "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
          "createdAt": "2022-10-20T11:43:06.184Z",
          "updatedAt": "2022-10-20T11:43:06.184Z"
        },
        {
          "id": "e13b0aa4-c59b-4d2b-8efc-50d4061077e4",
          "questionSetId": "70856eb6-75f7-4e59-afba-c3e999f34dcc",
          "sharedBy": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
          "sharedTo": "1fbe8a14-3ed7-426e-b84c-5afe6e4150f7",
          "status": "draft",
          "completedDate": null,
          "responseViewBySender": null,
          "questionnaireViewByReceiver": null,
          "reminderTypes": [],
          "reminderTypeDates": [],
          "reminderOn": null,
          "dueDate": null,
          "responseViewNotifyToPatient": false,
          "shareType": "unscheduled",
          "appointmentId": null,
          "resourceIds": null,
          "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
          "createdAt": "2022-09-30T09:55:37.269Z",
          "updatedAt": "2022-10-03T08:58:10.861Z"
        },
        {
          "id": "9cc1dbbd-6e53-4a2e-ba65-15028302bb94",
          "questionSetId": "70856eb6-75f7-4e59-afba-c3e999f34dcc",
          "sharedBy": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
          "sharedTo": "1fbe8a14-3ed7-426e-b84c-5afe6e4150f7",
          "status": "shared",
          "completedDate": null,
          "responseViewBySender": null,
          "questionnaireViewByReceiver": null,
          "reminderTypes": [],
          "reminderTypeDates": [],
          "reminderOn": null,
          "dueDate": null,
          "responseViewNotifyToPatient": false,
          "shareType": "unscheduled",
          "appointmentId": null,
          "resourceIds": null,
          "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
          "createdAt": "2022-10-20T11:39:07.905Z",
          "updatedAt": "2022-10-20T11:39:07.905Z"
        },
        {
          "id": "c1d7ebbf-2a53-422c-9581-750b43c3ada9",
          "questionSetId": "70856eb6-75f7-4e59-afba-c3e999f34dcc",
          "sharedBy": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
          "sharedTo": "1fbe8a14-3ed7-426e-b84c-5afe6e4150f7",
          "status": "shared",
          "completedDate": null,
          "responseViewBySender": null,
          "questionnaireViewByReceiver": null,
          "reminderTypes": [],
          "reminderTypeDates": [],
          "reminderOn": "2022-10-04T05:27:00.000Z",
          "dueDate": "2022-10-04T05:27:00.000Z",
          "responseViewNotifyToPatient": false,
          "shareType": "scheduled",
          "appointmentId": null,
          "resourceIds": null,
          "tpId": null,
          "createdAt": "2022-10-04T05:27:02.601Z",
          "updatedAt": "2022-10-04T05:27:02.601Z"
        },
        {
          "id": "14b25a18-6cb0-498c-8d78-96bf561a0f44",
          "questionSetId": "70856eb6-75f7-4e59-afba-c3e999f34dcc",
          "sharedBy": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
          "sharedTo": "1fbe8a14-3ed7-426e-b84c-5afe6e4150f7",
          "status": "shared",
          "completedDate": null,
          "responseViewBySender": null,
          "questionnaireViewByReceiver": null,
          "reminderTypes": [],
          "reminderTypeDates": [],
          "reminderOn": null,
          "dueDate": null,
          "responseViewNotifyToPatient": false,
          "shareType": "unscheduled",
          "appointmentId": null,
          "resourceIds": null,
          "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
          "createdAt": "2022-09-30T09:55:29.199Z",
          "updatedAt": "2022-09-30T09:55:29.199Z"
        },
        {
          "id": "01002f43-33c1-4377-bec1-3c615ee2f062",
          "questionSetId": "70856eb6-75f7-4e59-afba-c3e999f34dcc",
          "sharedBy": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
          "sharedTo": "ae5fb0d6-aedf-4882-903d-136a92ef52ee",
          "status": "shared",
          "completedDate": null,
          "responseViewBySender": null,
          "questionnaireViewByReceiver": null,
          "reminderTypes": [],
          "reminderTypeDates": [],
          "reminderOn": null,
          "dueDate": null,
          "responseViewNotifyToPatient": false,
          "shareType": "unscheduled",
          "appointmentId": null,
          "resourceIds": null,
          "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
          "createdAt": "2022-10-20T11:40:56.388Z",
          "updatedAt": "2022-10-20T11:40:56.388Z"
        }
      ],
      "questionSetTags": [],
      "isFavourite": false,
      "canDelete": false,
      "options": [
        {
          "id": "7f0985d1-589e-4d0c-bdab-dace2208b649",
          "text": "test1",
          "questionId": "24cff0af-3356-49da-ab6c-5ee46ce9a702",
          "rangeDescription": null,
          "sortOrder": 0,
          "score": 5,
          "keywords": [
            "ghjk"
          ]
        },
        {
          "id": "d1da2dfd-3bd0-4ef1-89fa-6c3a4139cd76",
          "text": "test2",
          "questionId": "24cff0af-3356-49da-ab6c-5ee46ce9a702",
          "rangeDescription": "",
          "sortOrder": 1,
          "score": 5,
          "keywords": [
            "gff"
          ]
        },
        {
          "id": "116445b6-80dc-485d-9254-ef9f857a2c6d",
          "text": "Yes",
          "questionId": "90c034cc-3a59-4ee6-9076-d63c95d660a8",
          "rangeDescription": null,
          "sortOrder": 0,
          "score": 5,
          "keywords": null
        },
        {
          "id": "9662c810-1e82-430b-9462-73db17d1006a",
          "text": "No",
          "questionId": "90c034cc-3a59-4ee6-9076-d63c95d660a8",
          "rangeDescription": null,
          "sortOrder": 1,
          "score": 3,
          "keywords": null
        },
        {
          "id": "a48202e5-f166-4959-8bbc-bea29a4969bb",
          "text": "drop",
          "questionId": "9fcaf7e7-fc1b-4282-948f-d37ce84935d2",
          "rangeDescription": null,
          "sortOrder": 0,
          "score": 3,
          "keywords": [
            "dss"
          ]
        },
        {
          "id": "eca85e84-3316-41b9-8672-5737a962a2f2",
          "text": "down",
          "questionId": "9fcaf7e7-fc1b-4282-948f-d37ce84935d2",
          "rangeDescription": "",
          "sortOrder": 1,
          "score": 3,
          "keywords": [
            "sdf"
          ]
        },
        {
          "id": "8cab1d79-f13c-49c7-8b7b-22d4af9dd0ab",
          "text": "1",
          "questionId": "fa4043ec-545c-432a-828b-8867f9dce5e8",
          "rangeDescription": null,
          "sortOrder": 0,
          "score": 2,
          "keywords": null
        },
        {
          "id": "186da7c3-c4a3-4612-88b1-034f0c81237e",
          "text": "2",
          "questionId": "fa4043ec-545c-432a-828b-8867f9dce5e8",
          "rangeDescription": null,
          "sortOrder": 1,
          "score": 2,
          "keywords": null
        },
        {
          "id": "4313b1f3-700c-41f2-a375-490e6d3705c4",
          "text": "3",
          "questionId": "fa4043ec-545c-432a-828b-8867f9dce5e8",
          "rangeDescription": null,
          "sortOrder": 2,
          "score": 2,
          "keywords": null
        },
        {
          "id": "acf62bd5-01ea-486a-8d7d-191d75792664",
          "text": "4",
          "questionId": "fa4043ec-545c-432a-828b-8867f9dce5e8",
          "rangeDescription": null,
          "sortOrder": 3,
          "score": 2,
          "keywords": null
        },
        {
          "id": "c8c15de1-1d57-4520-83ee-20632201729e",
          "text": "5",
          "questionId": "fa4043ec-545c-432a-828b-8867f9dce5e8",
          "rangeDescription": null,
          "sortOrder": 4,
          "score": 2,
          "keywords": null
        }
      ]
    },
    "msg": null
  }


  
  export const PreviewData= {
    "status": "success",
    "result": {
      "id": "70856eb6-75f7-4e59-afba-c3e999f34dcc",
      "name": "Covid 70",
      "description": null,
      "status": "approved",
      "approved": "true",
      "approvedBy": "0685583e-7b47-4713-8923-8098154544a2",
      "createdBy": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
      "pageNumber": false,
      "templateFrom": null,
      "category": "4118e383-551b-4f21-b8bf-743edccdebe4",
      "ageRange": "a48c6f1d-66af-455b-b9fa-0224d8f8de46",
      "copyRight": false,
      "tags": [],
      "public": false,
      "questionSetAuthor": {
        "id": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
        "firstName": "jayanthi",
        "lastName": "R",
        "email": "jayanthi+test2@mindwaveventures.com"
      },
      "questions": [
        {
          "id": "24cff0af-3356-49da-ab6c-5ee46ce9a702",
          "question": "multiple question",
          "description": null,
          "hasDescription": false,
          "hasConditionalQuestion": false,
          "hasImage": false,
          "imageUrl": null,
          "type": "multiplechoice",
          "isMandatory": false,
          "dependentQuestionId": null,
          "dependentOptionId": null,
          "sortOrder": 0
        },
        {
          "id": "5c4f8808-8914-4bfb-9721-63fa7422408b",
          "question": "enter text hello",
          "description": null,
          "hasDescription": false,
          "hasConditionalQuestion": false,
          "hasImage": false,
          "imageUrl": null,
          "type": "freetext",
          "isMandatory": false,
          "dependentQuestionId": null,
          "dependentOptionId": null,
          "sortOrder": 1
        },
        {
          "id": "90c034cc-3a59-4ee6-9076-d63c95d660a8",
          "question": "radio button",
          "description": null,
          "hasDescription": false,
          "hasConditionalQuestion": false,
          "hasImage": false,
          "imageUrl": null,
          "type": "yesnoquestion",
          "isMandatory": false,
          "dependentQuestionId": null,
          "dependentOptionId": null,
          "sortOrder": 2
        },
        {
          "id": "36561ae1-9485-43ea-9b90-0be762c09163",
          "question": "enter statement  list ",
          "description": null,
          "hasDescription": false,
          "hasConditionalQuestion": false,
          "hasImage": false,
          "imageUrl": null,
          "type": "statement",
          "isMandatory": false,
          "dependentQuestionId": null,
          "dependentOptionId": null,
          "sortOrder": 3
        },
        {
          "id": "9fcaf7e7-fc1b-4282-948f-d37ce84935d2",
          "question": "dropdown list",
          "description": null,
          "hasDescription": false,
          "hasConditionalQuestion": false,
          "hasImage": false,
          "imageUrl": null,
          "type": "dropdown",
          "isMandatory": false,
          "dependentQuestionId": null,
          "dependentOptionId": null,
          "sortOrder": 4
        },
        {
          "id": "4f3063e3-fe43-4030-ace2-6d80e8b589f2",
          "question": "section area",
          "description": null,
          "hasDescription": false,
          "hasConditionalQuestion": false,
          "hasImage": false,
          "imageUrl": null,
          "type": "section",
          "isMandatory": false,
          "dependentQuestionId": null,
          "dependentOptionId": null,
          "sortOrder": 5
        },
        {
          "id": "fa4043ec-545c-432a-828b-8867f9dce5e8",
          "question": "likert list question",
          "description": null,
          "hasDescription": false,
          "hasConditionalQuestion": false,
          "hasImage": false,
          "imageUrl": null,
          "type": "likert",
          "isMandatory": false,
          "dependentQuestionId": null,
          "dependentOptionId": null,
          "sortOrder": 6
        }
      ],
      "questionSetCategory": {
        "name": "Patient assessment",
        "id": "4118e383-551b-4f21-b8bf-743edccdebe4"
      },
      "questionSetAgeRange": {
        "name": "age"
      },
      "userFavourite": [
        {
          "id": "708d984e-8041-470c-af41-c133b75a72e0",
          "questionSetId": "70856eb6-75f7-4e59-afba-c3e999f34dcc",
          "userId": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
          "createdAt": "2022-09-27T13:30:38.442Z",
          "updatedAt": "2022-09-27T13:30:38.442Z"
        }
      ],
      "questionShared": [
        {
          "id": "9573cb59-0588-4ba5-b013-92b3174599d2",
          "questionSetId": "70856eb6-75f7-4e59-afba-c3e999f34dcc",
          "sharedBy": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
          "sharedTo": "1fbe8a14-3ed7-426e-b84c-5afe6e4150f7",
          "status": "shared",
          "completedDate": null,
          "responseViewBySender": null,
          "questionnaireViewByReceiver": null,
          "reminderTypes": [],
          "reminderTypeDates": [],
          "reminderOn": null,
          "dueDate": null,
          "responseViewNotifyToPatient": false,
          "shareType": "unscheduled",
          "appointmentId": null,
          "resourceIds": null,
          "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
          "createdAt": "2022-09-30T09:55:21.136Z",
          "updatedAt": "2022-09-30T09:55:21.138Z"
        },
        {
          "id": "b9bb8c11-c5d2-4a55-a32c-2a8b2e097ec3",
          "questionSetId": "70856eb6-75f7-4e59-afba-c3e999f34dcc",
          "sharedBy": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
          "sharedTo": "1fbe8a14-3ed7-426e-b84c-5afe6e4150f7",
          "status": "completed",
          "completedDate": "2022-09-30T09:52:32.793Z",
          "responseViewBySender": null,
          "questionnaireViewByReceiver": true,
          "reminderTypes": [],
          "reminderTypeDates": [],
          "reminderOn": null,
          "dueDate": null,
          "responseViewNotifyToPatient": false,
          "shareType": "unscheduled",
          "appointmentId": null,
          "resourceIds": null,
          "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
          "createdAt": "2022-09-30T06:43:29.363Z",
          "updatedAt": "2022-09-30T09:52:32.915Z"
        },
        {
          "id": "01002f43-33c1-4377-bec1-3c615ee2f062",
          "questionSetId": "70856eb6-75f7-4e59-afba-c3e999f34dcc",
          "sharedBy": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
          "sharedTo": "ae5fb0d6-aedf-4882-903d-136a92ef52ee",
          "status": "shared",
          "completedDate": null,
          "responseViewBySender": null,
          "questionnaireViewByReceiver": null,
          "reminderTypes": [],
          "reminderTypeDates": [],
          "reminderOn": null,
          "dueDate": null,
          "responseViewNotifyToPatient": false,
          "shareType": "unscheduled",
          "appointmentId": null,
          "resourceIds": null,
          "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
          "createdAt": "2022-10-20T11:40:56.388Z",
          "updatedAt": "2022-10-20T11:40:56.388Z"
        },
        {
          "id": "11856843-5eb6-45ac-9958-42d4db45abd3",
          "questionSetId": "70856eb6-75f7-4e59-afba-c3e999f34dcc",
          "sharedBy": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
          "sharedTo": "68fb5124-b4a9-4777-8752-ed8671fedf9f",
          "status": "shared",
          "completedDate": null,
          "responseViewBySender": null,
          "questionnaireViewByReceiver": null,
          "reminderTypes": [],
          "reminderTypeDates": [],
          "reminderOn": null,
          "dueDate": null,
          "responseViewNotifyToPatient": false,
          "shareType": "unscheduled",
          "appointmentId": null,
          "resourceIds": null,
          "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
          "createdAt": "2022-09-22T10:49:14.534Z",
          "updatedAt": "2022-09-22T10:49:14.534Z"
        },
        {
          "id": "d0866a9c-f02e-49aa-b341-7777211ca523",
          "questionSetId": "70856eb6-75f7-4e59-afba-c3e999f34dcc",
          "sharedBy": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
          "sharedTo": "1fbe8a14-3ed7-426e-b84c-5afe6e4150f7",
          "status": "shared",
          "completedDate": null,
          "responseViewBySender": null,
          "questionnaireViewByReceiver": null,
          "reminderTypes": [],
          "reminderTypeDates": [],
          "reminderOn": "2022-10-06T08:31:00.000Z",
          "dueDate": "2022-10-06T08:31:00.000Z",
          "responseViewNotifyToPatient": false,
          "shareType": "scheduled",
          "appointmentId": null,
          "resourceIds": null,
          "tpId": null,
          "createdAt": "2022-10-06T08:31:02.975Z",
          "updatedAt": "2022-10-06T08:31:02.976Z"
        },
        {
          "id": "9cc1dbbd-6e53-4a2e-ba65-15028302bb94",
          "questionSetId": "70856eb6-75f7-4e59-afba-c3e999f34dcc",
          "sharedBy": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
          "sharedTo": "1fbe8a14-3ed7-426e-b84c-5afe6e4150f7",
          "status": "completed",
          "completedDate": "2022-11-17T13:48:19.944Z",
          "responseViewBySender": null,
          "questionnaireViewByReceiver": null,
          "reminderTypes": [],
          "reminderTypeDates": [],
          "reminderOn": null,
          "dueDate": null,
          "responseViewNotifyToPatient": false,
          "shareType": "unscheduled",
          "appointmentId": null,
          "resourceIds": null,
          "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
          "createdAt": "2022-10-20T11:39:07.905Z",
          "updatedAt": "2022-11-17T13:48:20.013Z"
        },
        {
          "id": "145dca6b-ee47-4410-8a0f-7308b9724280",
          "questionSetId": "70856eb6-75f7-4e59-afba-c3e999f34dcc",
          "sharedBy": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
          "sharedTo": "1fbe8a14-3ed7-426e-b84c-5afe6e4150f7",
          "status": "shared",
          "completedDate": null,
          "responseViewBySender": null,
          "questionnaireViewByReceiver": null,
          "reminderTypes": [],
          "reminderTypeDates": [],
          "reminderOn": null,
          "dueDate": null,
          "responseViewNotifyToPatient": false,
          "shareType": "unscheduled",
          "appointmentId": null,
          "resourceIds": null,
          "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
          "createdAt": "2022-09-30T09:55:14.302Z",
          "updatedAt": "2022-09-30T09:55:14.302Z"
        },
        {
          "id": "e6525482-4a04-445e-83af-f35577bd00ec",
          "questionSetId": "70856eb6-75f7-4e59-afba-c3e999f34dcc",
          "sharedBy": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
          "sharedTo": "ee3e298a-4ca9-4d40-91bd-c8667be4c3ed",
          "status": "shared",
          "completedDate": null,
          "responseViewBySender": null,
          "questionnaireViewByReceiver": null,
          "reminderTypes": [],
          "reminderTypeDates": [],
          "reminderOn": null,
          "dueDate": "2022-10-29T18:30:00.000Z",
          "responseViewNotifyToPatient": false,
          "shareType": "unscheduled",
          "appointmentId": null,
          "resourceIds": null,
          "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
          "createdAt": "2022-10-20T11:48:21.799Z",
          "updatedAt": "2022-10-20T11:48:21.799Z"
        },
        {
          "id": "e13b0aa4-c59b-4d2b-8efc-50d4061077e4",
          "questionSetId": "70856eb6-75f7-4e59-afba-c3e999f34dcc",
          "sharedBy": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
          "sharedTo": "1fbe8a14-3ed7-426e-b84c-5afe6e4150f7",
          "status": "draft",
          "completedDate": null,
          "responseViewBySender": null,
          "questionnaireViewByReceiver": null,
          "reminderTypes": [],
          "reminderTypeDates": [],
          "reminderOn": null,
          "dueDate": null,
          "responseViewNotifyToPatient": false,
          "shareType": "unscheduled",
          "appointmentId": null,
          "resourceIds": null,
          "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
          "createdAt": "2022-09-30T09:55:37.269Z",
          "updatedAt": "2022-10-03T08:58:10.861Z"
        },
        {
          "id": "a3ed2f13-e050-493d-8790-b3ac66d1f2c4",
          "questionSetId": "70856eb6-75f7-4e59-afba-c3e999f34dcc",
          "sharedBy": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
          "sharedTo": "1fbe8a14-3ed7-426e-b84c-5afe6e4150f7",
          "status": "shared",
          "completedDate": null,
          "responseViewBySender": null,
          "questionnaireViewByReceiver": null,
          "reminderTypes": [],
          "reminderTypeDates": [],
          "reminderOn": null,
          "dueDate": "2022-10-25T11:39:11.000Z",
          "responseViewNotifyToPatient": false,
          "shareType": "unscheduled",
          "appointmentId": null,
          "resourceIds": null,
          "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
          "createdAt": "2022-10-20T11:39:25.106Z",
          "updatedAt": "2022-10-20T11:39:25.106Z"
        },
        {
          "id": "c1d7ebbf-2a53-422c-9581-750b43c3ada9",
          "questionSetId": "70856eb6-75f7-4e59-afba-c3e999f34dcc",
          "sharedBy": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
          "sharedTo": "1fbe8a14-3ed7-426e-b84c-5afe6e4150f7",
          "status": "shared",
          "completedDate": null,
          "responseViewBySender": null,
          "questionnaireViewByReceiver": null,
          "reminderTypes": [],
          "reminderTypeDates": [],
          "reminderOn": "2022-10-04T05:27:00.000Z",
          "dueDate": "2022-10-04T05:27:00.000Z",
          "responseViewNotifyToPatient": false,
          "shareType": "scheduled",
          "appointmentId": null,
          "resourceIds": null,
          "tpId": null,
          "createdAt": "2022-10-04T05:27:02.601Z",
          "updatedAt": "2022-10-04T05:27:02.601Z"
        },
        {
          "id": "14b25a18-6cb0-498c-8d78-96bf561a0f44",
          "questionSetId": "70856eb6-75f7-4e59-afba-c3e999f34dcc",
          "sharedBy": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
          "sharedTo": "1fbe8a14-3ed7-426e-b84c-5afe6e4150f7",
          "status": "shared",
          "completedDate": null,
          "responseViewBySender": null,
          "questionnaireViewByReceiver": null,
          "reminderTypes": [],
          "reminderTypeDates": [],
          "reminderOn": null,
          "dueDate": null,
          "responseViewNotifyToPatient": false,
          "shareType": "unscheduled",
          "appointmentId": null,
          "resourceIds": null,
          "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
          "createdAt": "2022-09-30T09:55:29.199Z",
          "updatedAt": "2022-09-30T09:55:29.199Z"
        },
        {
          "id": "bb175ea5-8a0e-4cee-afad-74be230147b3",
          "questionSetId": "70856eb6-75f7-4e59-afba-c3e999f34dcc",
          "sharedBy": "5e2886dc-dd9f-4f26-b1b6-36165de904ce",
          "sharedTo": "250b3425-3dd5-43e9-8e99-e7fa5e9bb241",
          "status": "shared",
          "completedDate": null,
          "responseViewBySender": null,
          "questionnaireViewByReceiver": null,
          "reminderTypes": [],
          "reminderTypeDates": [],
          "reminderOn": null,
          "dueDate": null,
          "responseViewNotifyToPatient": false,
          "shareType": "unscheduled",
          "appointmentId": null,
          "resourceIds": null,
          "tpId": "3a8edf2a-6e2f-41de-904e-61f4cf9dcdc0",
          "createdAt": "2022-10-20T11:43:06.184Z",
          "updatedAt": "2022-10-20T11:43:06.184Z"
        }
      ],
      "questionSetTags": [],
      "isFavourite": false,
      "canDelete": false,
      "options": [
        {
          "id": "7f0985d1-589e-4d0c-bdab-dace2208b649",
          "text": "test1",
          "questionId": "24cff0af-3356-49da-ab6c-5ee46ce9a702",
          "rangeDescription": null,
          "sortOrder": 0,
          "score": 5,
          "keywords": [
            "ghjk"
          ]
        },
        {
          "id": "d1da2dfd-3bd0-4ef1-89fa-6c3a4139cd76",
          "text": "test2",
          "questionId": "24cff0af-3356-49da-ab6c-5ee46ce9a702",
          "rangeDescription": "",
          "sortOrder": 1,
          "score": 5,
          "keywords": [
            "gff"
          ]
        },
        {
          "id": "116445b6-80dc-485d-9254-ef9f857a2c6d",
          "text": "Yes",
          "questionId": "90c034cc-3a59-4ee6-9076-d63c95d660a8",
          "rangeDescription": null,
          "sortOrder": 0,
          "score": 5,
          "keywords": null
        },
        {
          "id": "9662c810-1e82-430b-9462-73db17d1006a",
          "text": "No",
          "questionId": "90c034cc-3a59-4ee6-9076-d63c95d660a8",
          "rangeDescription": null,
          "sortOrder": 1,
          "score": 3,
          "keywords": null
        },
        {
          "id": "a48202e5-f166-4959-8bbc-bea29a4969bb",
          "text": "drop",
          "questionId": "9fcaf7e7-fc1b-4282-948f-d37ce84935d2",
          "rangeDescription": null,
          "sortOrder": 0,
          "score": 3,
          "keywords": [
            "dss"
          ]
        },
        {
          "id": "eca85e84-3316-41b9-8672-5737a962a2f2",
          "text": "down",
          "questionId": "9fcaf7e7-fc1b-4282-948f-d37ce84935d2",
          "rangeDescription": "",
          "sortOrder": 1,
          "score": 3,
          "keywords": [
            "sdf"
          ]
        },
        {
          "id": "8cab1d79-f13c-49c7-8b7b-22d4af9dd0ab",
          "text": "1",
          "questionId": "fa4043ec-545c-432a-828b-8867f9dce5e8",
          "rangeDescription": null,
          "sortOrder": 0,
          "score": 2,
          "keywords": null
        },
        {
          "id": "186da7c3-c4a3-4612-88b1-034f0c81237e",
          "text": "2",
          "questionId": "fa4043ec-545c-432a-828b-8867f9dce5e8",
          "rangeDescription": null,
          "sortOrder": 1,
          "score": 2,
          "keywords": null
        },
        {
          "id": "4313b1f3-700c-41f2-a375-490e6d3705c4",
          "text": "3",
          "questionId": "fa4043ec-545c-432a-828b-8867f9dce5e8",
          "rangeDescription": null,
          "sortOrder": 2,
          "score": 2,
          "keywords": null
        },
        {
          "id": "acf62bd5-01ea-486a-8d7d-191d75792664",
          "text": "4",
          "questionId": "fa4043ec-545c-432a-828b-8867f9dce5e8",
          "rangeDescription": null,
          "sortOrder": 3,
          "score": 2,
          "keywords": null
        },
        {
          "id": "c8c15de1-1d57-4520-83ee-20632201729e",
          "text": "5",
          "questionId": "fa4043ec-545c-432a-828b-8867f9dce5e8",
          "rangeDescription": null,
          "sortOrder": 4,
          "score": 2,
          "keywords": null
        }
      ]
    },
    "msg": null
  }

  export const shareUser={
    "status": "success",
    "result": [
      {
        "uuid": "46c59b69-7d15-49c1-b737-fb722da10f6e",
        "firstName": "Sathish",
        "lastName": "Kumar",
        "role": "User",
        "email": "sathish@mindwaveventures.com",
        "profilePic": "https://mvphrstagingstorage.blob.core.windows.net/amity1664447905598imglola-rose-840tjesh9ww-unsplash.jpg",
        "shared": false,
        "isScheduled": false,
        "scheduledDate": null
      },
      {
        "uuid": "b0a97665-37e9-4e14-a535-9820267c7b91",
        "firstName": "selva",
        "lastName": "selva",
        "role": "User",
        "email": "selva+patient@mailinator.com",
        "profilePic": null,
        "shared": false,
        "isScheduled": false,
        "scheduledDate": null
      },
      {
        "uuid": "5c843f5a-051b-414c-bd76-4c2632e06a78",
        "firstName": "thiru",
        "lastName": "thiru",
        "role": "User",
        "email": "thiru+patient@mailinator.com",
        "profilePic": null,
        "shared": false,
        "isScheduled": false,
        "scheduledDate": null
      },
      {
        "uuid": "eb250a02-e29f-4fab-811c-7a2f8996bef4",
        "firstName": "siva",
        "lastName": "patient",
        "role": "User",
        "email": "siva+patient@mailinator.com",
        "profilePic": null,
        "shared": false,
        "isScheduled": false,
        "scheduledDate": null
      },
      {
        "uuid": "d915d5b8-4b92-4ac2-b15f-46e4c863cb89",
        "firstName": "vidhya",
        "lastName": "vidhya",
        "role": "User",
        "email": "vidhya+patient@mailinator.com",
        "profilePic": null,
        "shared": false,
        "isScheduled": false,
        "scheduledDate": null
      },
      {
        "uuid": "7f5aa4c1-d262-4fb1-8940-a2d5a5dcedfd",
        "firstName": "sathish",
        "lastName": "kumar",
        "role": "User",
        "email": "sathish@mailinator.com",
        "profilePic": null,
        "shared": false,
        "isScheduled": false,
        "scheduledDate": null
      }
    ],
    "msg": null
  }