import React from "react";
import { Formik, Form } from "formik";
import { TextField } from "../../components";
import "react-tabs/style/react-tabs.css";
import "../../style/pages/userlisting.css";
import { Button } from "../../components/Button";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function HomePage() {
  const navigate = useNavigate();
  const signupDetails = useSelector(
    (state: any) => state.signupReducer.signupDetails
  );
  if (!signupDetails.data) {
    navigate("/", { replace: true });
  }

  return (
    <div className="overall-bg">
      <div className="table-container">
        <div className="table-spacing">
          <div className="user-txt">
            <h1>Good morning {signupDetails.firstname}</h1>
            <Formik
              initialValues={{}}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  alert(JSON.stringify(values, null, 2));
                  setSubmitting(false);
                }, 400);
              }}
            >
              <Form>
                <TextField
                  type="search"
                  id="search"
                  name="search"
                  placeholder="Search"
                />
              </Form>
            </Formik>
          </div>

          <div>
            <Button type="submit" text="User listing" addClass="card-btn" />
            <Button type="submit" text="Create new form" addClass="card-btn" />
            <Button
              type="submit"
              text="View current forms"
              addClass="card-btn"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
