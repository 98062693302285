import React, { Fragment } from "react";
//import { useNavigate } from 'react-router-dom';
import { Header } from "../components/Header";
import "../style/pages/auth.css";
//import {useNavigate} from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
interface MainLayoutProps {
  children: any;
  headertext?: string;
}

export const StaffMainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  // const navigate=useNavigate();
  const { instance } = useMsal();
  const handleLogout = (logoutType: string) => {
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/", // redirects the top level app after logout
      });
    }
  };
  return (
    <Fragment>
      <section>
        <Header probileshow />
        <div className="logout-text">
          <a onClick={() => handleLogout("popup")}>Logout</a>
          {/* <a onClick={()=>navigate('/profile')}>Profile</a> */}
          {/* <a onClick={()=>navigate('/')}>Home</a> */}
        </div>
        {children}
      </section>
    </Fragment>
  );
};
