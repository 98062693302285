import { lazy } from 'react';

export default [{
    title: 'Landing Page',
    component: lazy(() => import('./Landing')),
    url: '/users/landing',
    heading:'Home screen',
    requirePermission: true,
    roles: ['Super_Admin','Admin','User'],
}];
