import React from "react";
import { LogoHeader } from "../components/Questionnaire/Header/LogoHeader";
import { PrimaryNav } from "../components/Questionnaire/Header/PrimaryNav";
import { SecondaryNav } from "../components/Questionnaire/Header/SecondaryNav";

interface QuestionLayoutProps {
  children: any;
}

export const QuestionLayout: React.FC<QuestionLayoutProps> = ({ children }) => {
  return (
    <>
      <div>
        <LogoHeader />
        <PrimaryNav />
        <SecondaryNav />
      </div>
      {children}
    </>
  );
};
