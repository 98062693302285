import { lazy } from 'react';

export default [{
    title: 'List Tab Page',
    component: lazy(() => import('./ListTabs')),
    url: '/userlist/listtab',
    heading:'User listing',
    requirePermission: true,
    roles: ['Super_Admin','Admin','User'],
}];
