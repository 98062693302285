import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const signupnewSlice = createSlice({
    name: 'signupnew',
    initialState:{signupDetails:{ 
        firstname:'',
        lastname:'',
        email:'',
    }},
    reducers: {
        setSignup: (state: any, action: PayloadAction<object>) => {
            state.signupDetails = action.payload;
        }
    }
});

export const { setSignup } = signupnewSlice.actions;

export default signupnewSlice.reducer;
