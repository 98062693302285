import { lazy } from 'react';

export default [{
    title: 'Clinician Specialist Page',
    component: lazy(() => import('./ClinicianSpecialist')),
    url: '/dashboard/clinicianspecialist',
    requirePermission: true,
    roles: ['Super_Admin','Admin','User'],
},{
    title: 'Staff Admin Page',
    component: lazy(() => import('./StaffAdmin')),
    url:  '/dashboard/staffadmin',
    requirePermission: false,
    roles: ['Super_Admin','Admin','User'],
}];
