import configJson from "../config/config";

const siteUrlsJson = {
  ourSiteUrls: {
    login: "auth/login",
    loginWithOTP: "auth/login-with-otp",
    register: "auth/sign-up",
    check: "auth/account/check",
    resetPassword: "auth/reset-password",
    forgotPassword: "auth/send-reset-password-mail",
    checkForgotPassToken: "account/check-forgot-token-expire",
    verifyAccountEmail: "auth/verify-user",
    getUserInfo: "account/get-user-data",
    verifyPasswordToken: "auth/verify-token-valid",
    thirdPartySignup: "auth/third-party-auth",
    getNotification: "auth/notify",
    nhsLogin: "auth/nhs-login",
    thirdPartyUserCheck: "auth/third-party-auth",
    normalUserCheck: "auth/check-user-exists",
    masterGet: "master/get-list",
    globalSettings: {
      update: "app-settings/update",
      get: "auth/get-app-settings",
    },
    notification: {
      clear: "auth/notify/remove/all",
    },
    auditTrail: {
      create: "/audit-trail",
      get: "/audit-trail",
      getAccountHistory: "/audit-trail/accountHistory",
    },
    callbacks: "/callbacks",
    callbacksByDate: "/callbacksByDate",
    adminFunctions: {
      callbacks: "/admin/callbacks",
      waitlist: "/admin/waitlist",
      deleteRequestedUserDeletion: "/admin/userDeletion"
    },
    appointmentBooking: {
      clinicalInfo: {
        getClinicalInfo: "/getClinicalInfo",
      },
    },
    questionnarie: {
      dashboard: {
        getDashboardCard: "/questionnaire/dashboard/card",
        updateClientDashboard: "/clientDashboard",
        updateClientDashboardSet: "/questionnaire/updateClientDashboardSet",
        getClientDashboard: "/clientDashboardByService",
        getAllClientDashboard: "/allClientDashboardByService",
        externalUserByCondition: "/externalUserByCondition",
        getDashboardCardPinnedLogs: "/questionnaire/dashboard/cardlogs",
        updateDashboardCard: "/questionnaire/dashboard/card",
        getAllCallList: "/questionnaire/callback",
        getCompletedCallback: "/questionnaire/completedcallback",
        getUnattendedCallback: "/questionnaire/unattendedcallback",
        getAllWorkList: "/questionnaire/worklist",
        updateTriageAction: "/triage-work-list",
        triggerOverallCount: "triage-work-list/count",
        getCompletedWorkList: "/questionnaire/completedWorklist",
        clientOverviewDetailsByIDAndService:
          "/questionnaire/clientOverviewDetailsByID",
        clientRecord: "/clientRecords",
        multipleAppointment: "/maxims/appointment/"
      },
      ageGroup: {
        request: "questionnaire/range/request",
        getAll: "questionnaire/range/all",
        add: "questionnaire/range",
        delete: "questionnaire/range",
        approveOrReject: "questionnaire/range/approve",
      },
      category: {
        request: "questionnaire/category/request",
        getCategory: "questionnaire/category",
        addCategory: "questionnaire/category",
        deleteCategory: "questionnaire/category",
        approveOrReject: "questionnaire/category/approve",
      },
      favourite: {
        updateStatus: "questionnaire/favourite",
      },
      updateRecommendResource: "resource/recommend",
      patientAnswer: "questionnaire/answer",
      adminUpdatePatientAnswer: "/questionnaire/updateAnswerByAdmin",
      delete: "questionnaire",
      checkQuestionnarieDeleteStatus: "check-questionnaire-delete-status",
      getQuestionnarieOptions: "questionnaire/servicesCategory",
      checkQuestionName: "questionnaire/checkName",
      checkExistingQuestionName: "questionnaire/checkExistingName",
      requestApprovalsCount:
        "questionnaire/trust/admin/request-approvals/count",
      createQuestionnarie: "questionnaire",
      updateQuestionnarie: "questionnaire/update",
      updateQuestionnariename: "questionnaire/update/rename",
      commentQuestionnarie: "questionnaire/comment",
      getQuestionnarieById: "questionnaire",
      getQuestionnarie: "questionnaire",
      approvalRequest: "questionnaire/approve/request",
      receivedApprovalRequest: "questionnaire/approve/received",
      submittedApprovalFormsList: "/questionnaire/approve/send",
      getFormsByUserID: "/questionnaireFormsByUserID",
      statusForApprovalRequest: "questionnaire/approve",
      getDashboard: "questionnaire/dashboard",
      filter: "questionnaire/filter",
      shareQuestionnarie: "questionnaire/share",
      shareCounsellingQuestionnarie: "questionnaire/consultationFormShare",
      createDuplicateQuestionnaire: "questionnaire/duplicate",
      getResponse: "questionnaire/share",
      getResponseInDetail: "questionnaire/response",
      enableDisablePublic: "questionnaire/public",
      getClientForms: "questionnaire/client/forms",
      getClientFormsResponses: "questionnaire/client/forms/responses",
      sendInstandReminder: "questionnaire/instantReminder",
      responsePdfDownlaod: "questionnaire/pdf",
      exportQuestionnaireCsv: "questionnaire/export/csv",
      responseExportCsv: "questionnaire/response/export/csv",
      resposeDownloadPdf: "questionnaire/answer/response/pdf",
      scheduleShareQuestionnarie: "questionnaire/schedule/share",
      getScheduleShareQuestionnaire: "questionnaire/schedule/share",
      getAllQuestionnaire: "questionnaire/all",
      getActivityLogs: "questionnaire/activitylogs",
      getAllTriggerCategory: "/questionnaire/triggerCategory",
      postQBTriggerCategoryName: "/questionnaire/createQBTriggerCategoryName",
      putQBTriggerWords: "/questionnaire/createQBTriggerWords",
      deleteQBTriggerCategory: "/questionnaire/deleteQBTriggerCategory",
      checkQBTriggerCategory: "/questionnaire/triggers",
      getQBTriggerCategoryName: "/questionnaire/getQBTriggewords",
      updateQuestionnaireShareByUserId: "/updateQuestionnaireShareByUserId",
      getQuestionnaireShareByUserIdBoolean:
        "/getQuestionnaireShareByUserIdBoolean",
    },
    user: {
      getProfile: "user",
      getUserbyRole: "/getUsersByRole",
      getUserbyRoles: "/getUsersByRoles",
      thirdpartyLoginCheck: "auth/thirdparty/login",
      getAllStaffUsers: "/getAllStaffUsers",
      updateStaffUserByID: "/updateStaffUsersStatus",
      createUser: "user",
      updateUser: "user",
      updateDashboardCallbackById: "/admin/dashboard/callback",
      updateUserInfo: "updateUserInfo",
      updateUserSecurityPreferences: "updateUserSecurityPreferences",
      updateUserAutomatedMessagingPreferences:
        "updateUserAutomatedMessagingPreferences",
      updateUserContactPreferences: "updateUserContactPreferences",
      updateExternalUser: "externalUser",
      setPassword: "auth/reset",
      resetPasswordWithOld: "auth/resetPassword",
      getClientList: "getUsersbyService",
      getUserById: "user",
      adminChangeClientPasswordById: "/clientLoginCredentialReset",
      clientSafeguard: "/client-safeguard",
      clientRightcare: "/client-rightcare",
      clientIrToAdmin: "/client-ir-to-admin",
      clientIrToClinic: "/client-ir-to-clinic",
      getUserEligibilityContract: "/userEligibilityContract",
    },
    clientAdmin: {
      getAllUser: "user/all",
      getThirdPartyScope: "thirdparty/scope",
      getThirdPartyUser: "thirdparty/user",
      getTheme: "theme",
      saveTheme: "theme",
      deleteTheme: "theme",
      roles: {
        getAllRole: "role",
        scope: "scope",
        save: "role",
      },
    },
    resource: {
      getContentsResource: "resource/contents",
      getFilterResource: "resource/filters",
    },
    trust: {
      getTrustDetails: "thirdparty/",
      createTrust: "auth/create/thirdparty",
      allTrust: "thirdparty",
      resetLink: "auth/resendWelcomeEmail",
      count: "thirdparty/getDashbordCount",
      updateTrust: "thirdparty",
      trustTheme: "theme",
      getTheme: "theme",
      updateTheme: "theme",
      delete: "theme",
    },
    thirdParty: {
      createThirdParty: "thirdparty/addApi",
      thirdParty: "thirdparty/getApi",
      patchThirdParty: "thirdparty/updateApi",
      delete: "thirdparty/deleteApi",
    },
    upload: {
      image: "upload/file",
    },
  },
  outerDomainUrls: {
    upload: {
      image: `${process.env.REACT_APP_SERVICE_UPLOAD}upload/file`,
    },
  },
};
function checkInnerJson(jsonData: any) {
  if (jsonData) {
    for (const key in jsonData) {
      if (typeof jsonData[key] === "string") {
        jsonData[key] = `${configJson.backendDomain}${jsonData[key]}`;
      } else {
        jsonData[key] = checkInnerJson(jsonData[key]);
      }
    }
  }
  return jsonData as typeof siteUrlsJson.ourSiteUrls;
}
export const siteUrls = {
  ...checkInnerJson(siteUrlsJson.ourSiteUrls),
  outerDomainUrls: siteUrlsJson.outerDomainUrls,
};
