import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        name: 'tester'
    },
    reducers: {
        setName: (state: any, action: PayloadAction<object>) => {
            state.name = action.payload;
        }
    }
});

export const { setName } = authSlice.actions;

export default authSlice.reducer;
