import { lazy } from 'react';

export default [{
    title: 'Add List Tab Page',
    component: lazy(() => import('./ListTabs')),
    url: '/adduser/listtab',
    heading:'Add User',
    requirePermission: false,
    roles: ['Super_Admin','Admin','User'],
}];
