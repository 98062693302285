import { lazy } from "react";

export default [
  {
    title: "Login Page",
    component: lazy(() => import("./Login")),
    url: "/auth/login",
    requirePermission: false,
  },
  {
    title: "Receive Verification Code",
    component: lazy(() => import("./ReceiveVC")),
    url: "/login/receiveverificationcode",
    requirePermission: false,
  },
  {
    title: "Verification Code",
    component: lazy(() => import("./VerificationCode")),
    url: "/login/verificationcode",
    requirePermission: false,
  },
  {
    title: "Forgot Password",
    component: lazy(() => import("./ForgotPassword")),
    url: "/login/forgotpassword",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "User"],
  },
  {
    title: "Forgot Pin",
    component: lazy(() => import("./ForgotPin")),
    url: "/login/forgotpin",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "User"],
  },
];
