const appUrlsJson = {
  ourAppUrls: {
    loginform: "/auth/login",
    resetPassword: "/set-password",
    formAndQuestinnarie: "/questionnaires",
    createExistingQuestinnarie: "/existing-questionnaires",
    createQuestinnarie: "/questionnaires/create",
    questionnaireBuilder: "/questionnaires/builder/:id?",
    questionnaireBuilderWithoutID: "/questionnaires/builder/",
    questionnaireByType: "/questionnaires/type/:id",
    questionnaireByTypeName: "/questionnaires/type/",
    questionnaireResponses: "/responses",
    patient: {
      formsWithID: "/questionnaires/form/",
      dashboard: "/patient-forms",
      formTypes: "/patient/forms",
      submittedForms: "/submitted-forms",
      draftForms: "/draft-forms",
    },
    trustAdminMenu: {
      trustUsers: "/users",
    },
    questionnaireDashboard: "/questionnaires/form",
    scheduledForm: "/schedules-form",
    formApprovals: "/questionnaires/form/approvals",
    trustRoles: "/roles",
    trustUsers: "/staff",
    trustAdmin: "/admin",
    trustRequestForm: "/request-forms",
    systemAdminQuestionnaire: "/questionnarie-forms",
    trustRequestApprovals: "/request-approvals",
    trustAgeRequestApprovals: "/age-request-approvals",
    trustRequestSubmission: "/request-submission",
    trustAgeRequestSubmission: "/age-request-submission",
    trustThemeSetting: "/form-skins",
    mvDashboard: "/admin-dashboard",
    trustUserList: "/trust-users",
    mvTrustRoles: "/trust-roles",
    systemadmin: {
      trustOverview: "/trust/overview",
      trustSettings: "/trust/overview/settings",
    },
    trustViewSetting: "/trust-viewsetting",
    mvProfileSetting: "/profile-setting",
    poc: {
      dynamicForm: "/poc/dynamic-form",
    },
  },
  outerDomainUrls: {
    maiaParent: {
      logout: "https://staging.maiaphr.com/logout",
      profileAccount: "https://staging.maiaphr.com/account/details",
    },
  },
};

function checkInnerJson(jsonData: any) {
  if (jsonData) {
    for (const key in jsonData) {
      if (typeof jsonData[key] === "string") {
        jsonData[key] = `${jsonData[key]}`;
      } else {
        jsonData[key] = checkInnerJson(jsonData[key]);
      }
    }
  }
  return jsonData as typeof appUrlsJson.ourAppUrls;
}

export const appUrls = {
  ...checkInnerJson(appUrlsJson.ourAppUrls),
  outerDomainUrls: appUrlsJson.outerDomainUrls,
};
