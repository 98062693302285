import { lazy } from "react";

export default [
  {
    title: "Appointment List",
    component: lazy(() => import("./AppointmentList")),
    url: "/appointment/list",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Counselling Page",
    component: lazy(() => import("./Counselling")),
    url: "/counselling",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Counselling Page",
    component: lazy(() => import("./Counselling")),
    url: "/counselling/:id/:shareId",
    requirePermission: false,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Booking",
    component: lazy(() => import("./Booking")),
    url: "/appointment/booking",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Waiting List",
    component: lazy(() => import("./WaitingList")),
    url: "/appointment/waitinglist",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "No Appointment",
    component: lazy(() => import("./NoAppointment")),
    url: "/appointment/noappointment",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Contact Preference",
    component: lazy(() => import("./ContactPreference")),
    url: "/appointment/contactpreference",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Clinic Info",
    component: lazy(() => import("./ClinicInfo")),
    url: "/appointment/clinicinfo",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Interpreter",
    component: lazy(() => import("./Interpreter")),
    url: "/appointment/Interpreter",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Interpreter",
    component: lazy(() => import("./Interpreter")),
    url: "/appointment/:id/:shareId",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Booking Confirmed",
    component: lazy(() => import("./BookingConfirmed")),
    url: "/appointment/booking_confirmed",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "PhoneModule",
    component: lazy(() => import("./PhoneModule")),
    url: "/appointment/phonemodule",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "PhoneModule",
    component: lazy(() => import("./PhoneModule")),
    url: "/appointment/phonemodule/:id/:shareId",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "TopEligibility",
    component: lazy(() => import("./TopEligibility")),
    url: "/appointment/topeligibility",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
];
