import React, { FC, ReactElement } from "react";
import { Toaster } from "react-hot-toast";

export const Notifications: FC = (): ReactElement => {
  const toastOption: any = {
    duration: 4000,
    style: {
      background: "#0060af",
      color: "#fff",
    },
  };
  return <Toaster position="bottom-center" toastOptions={toastOption} />;
};
