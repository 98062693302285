/*
reducers are called from context. so look in AuthContext.ts
*/
import { IGlobelSettings, IUserInfo } from '../../models/Types';

// the possible values for action.type in
// our AuthReducer fn
export type AuthAction =
  | {
      type: 'LOGIN';
    }
  | {
      type: 'LOAD_USER';
      user: IUserInfo;
    }
  | {
      type: 'SET_GLOBAL_SETTINGS';
      globalSettings: IGlobelSettings;
    }
  | {
      type: 'SET_HEADER_HEIGHT';
      headerHeight: string | null;
    }
  | {
      type: 'SET_MULTI_APPOINTMENT_HEIGHT';
      multiAptHeight: string | null;
    }
  | { type: 'LOGOUT' };

export interface AuthState {
  isAuthenticated: boolean;
  globalSettings: IGlobelSettings;
  user: IUserInfo | null;
  headerHeight?: string | null;
  multiAptHeight?: string | null;
}

// when we hook up the app, the user is not
// going to be present in state.
export const initialState: AuthState = {
  isAuthenticated: false,
  globalSettings: {
    theme: [],
    modules: {
      card_question: false,
      questionnaires: {
        dateinput: false,
        numberinput: false,
        multipleChoice: false,
        statement: false,
        pictureChoice: false,
        freeText: false,
        yesNo: false,
        rating: false,
        likert: false,
        dropdown: false,
        opening: false,
        section: false,
        closing: false,
      },
    },
  },
  user: null,
  headerHeight: null,
  multiAptHeight: null,
};

// a reducer give back a state
export const authReducer = (
  state: AuthState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, isAuthenticated: true };
    case 'LOAD_USER': {
      return { ...state, user: action.user };
    }
    case 'SET_GLOBAL_SETTINGS': {
      return { ...state, globalSettings: action.globalSettings };
    }
    case 'LOGOUT': {
      return { ...state, isAuthenticated: false, user: null };
    }
    case 'SET_HEADER_HEIGHT': {
      return { ...state, headerHeight: action?.headerHeight || '80' };
    }
    case 'SET_MULTI_APPOINTMENT_HEIGHT': {
      return { ...state, multiAptHeight: action?.multiAptHeight };
    }
    default:
      return state;
  }
};
