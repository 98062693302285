import authModules from './pages/auth';
import loginModules from './pages/auth/LogIn';
import signupModules from './pages/auth/SignUp';
import resetModules from './pages/auth/Reset';
import homeModules from './pages/home';
import adminModules from './pages/admin';
import usersModules from './pages/users';
import serviceModules from './pages/service';
import topModules from './pages/top';
import vasModules from './pages/vas';
import larcModules from './pages/larc';
import phoneModules from './pages/PhoneModule';
import profileModules from './pages/profile';
import AccountModules from './pages/Account';
import userlistingModules from './pages/userlisting';
import userlistModules from './pages/userlisting/UserList';
import adduserModules from './pages/userlisting/AddUser';
import dashboardModules from './pages/userlisting/Dashboard';
import eligibilityModules from './pages/eligibility';
import consultationModules from './pages/Consultation';
import bookappointmentModules from './pages/BookAppointment';
import bookvasappointmentModules from './pages/VasAppointment';
import questionnaires from './pages/questionnaires';
import Adminquestionnaires from './pages/questionnaires/Admin';
import bookcounsellingappointmentModules from './pages/Counselling';
import LarcAppointmentModules from "./pages/LarcAppointment";
import TopBookAppointment from "./pages/TopAppointment";

export default [
  ...authModules,
  ...loginModules,
  ...signupModules,
  ...resetModules,
  ...homeModules,
  ...adminModules,
  ...usersModules,
  ...serviceModules,
  ...topModules,
  ...larcModules,
  ...phoneModules,
  ...profileModules,
  ...userlistingModules,
  ...userlistModules,
  ...adduserModules,
  ...eligibilityModules,
  ...consultationModules,
  ...bookappointmentModules,
  ...dashboardModules,
  ...questionnaires,
  ...Adminquestionnaires,
  ...vasModules,
  ...AccountModules,
  ...bookvasappointmentModules,
  ...bookcounsellingappointmentModules,
  ...LarcAppointmentModules,
  ...TopBookAppointment
];
