import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMultipleAppointment } from "../services/api/QuestionnarieApi";

interface IMultiAppointmentItem {
  appointmentDisplayName: string;
  appointmentType: { code: string; display: string };
  clinicalDetails: {
    additionalLocationNotes: string;
    addressLine1: string;
    addressLine2: string;
    cityOrTown: string;
    locationNameOnPortal: string;
    maximsLocationID: string;
    postcode: string;
  };
  created: Date;
  end: Date;
  id: string;
  start: Date;
  status: string;
}

interface IMultiAppointmentState{
  isLoader: boolean,
  result: IMultiAppointmentItem[],
  errorMessage: string,
  selectedAppointment?: IMultiAppointmentItem | null,
  isEnablePostAbortionCard?: boolean
}
const initialState: IMultiAppointmentState = {
  isLoader: false,
  result: [],
  errorMessage: "",
  selectedAppointment: null,
  isEnablePostAbortionCard: false,
};

// Get triggers overall counts
export const getMultipleAppointments = createAsyncThunk(
  "retrieve/multipleAppointment",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await getMultipleAppointment(payload);
      return response;
    } catch (error: any) {
      throw rejectWithValue(error.message);
    }
  }
);

// Then, handle actions in your reducers:
const multipleAppointmentSlice = createSlice({
  name: "triagePending",
  initialState,
  reducers: {
    setSelectedAppointmentCard: (state: any, { payload }: any) => {
      const appointmentList = JSON.parse(JSON.stringify(state?.result));
      const selectedItem = appointmentList && appointmentList.length > 0
          ? appointmentList.filter((item: any) => item?.id === payload)
          : [];
      return {
        ...state,
        selectedAppointment:
          selectedItem && selectedItem.length > 0 ? selectedItem[0] : {},
      };
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(getMultipleAppointments.pending, (state: any) => {
        // console.log("pending :>>");
        return {
          ...state,
          isLoader: true,
        };
      })
      .addCase(getMultipleAppointments.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        // console.log("fulfilled :>>", payload.data);
        return {
          ...state,
          isLoader: false,
          result: payload.data?.appointments || [],
          isEnablePostAbortionCard: payload.data?.posttoplarc
        };
      })
      .addCase(getMultipleAppointments.rejected, (state: any, action) => {
        const { payload } = action;
        // console.log("rejected :>>", payload);
        return {
          ...state,
          errorMessage: payload || "Failed API",
        };
      });
  },
});

export const { setSelectedAppointmentCard } = multipleAppointmentSlice.actions;

const { reducer } = multipleAppointmentSlice;

export default reducer;