import { lazy } from 'react';

export default [
    {
    title: 'Staff/ Admin Page',
    component: lazy(() => import('./Home')),
    url: 'Staff/AdminDashboard',
    heading:'Staff/ Admin dashboard',
    requirePermission: false,
    roles: ['Super_Admin','Admin','User'],
},
{
    title: 'Login Page',
    component: lazy(() => import('./Login')),
    url: '/userlisting/login',
    heading:'Staff / Admin / Clinician/ Specialist / login',
    requirePermission: true,
    roles: ['Super_Admin','Admin','User'],
},{
    title: 'Signup Page',
    component: lazy(() => import('./Signup')),
    url: '/userlisting/Signup',
    heading:'Staff / Admin Sign up',
    requirePermission: true,
    roles: ['Super_Admin','Admin','User'],
},{
    title: 'Sent Details Page',
    component: lazy(() => import('./SentDetails')),
    url: '/userlisting/sentdetails',
    heading:'Staff / Admin Sign up - details sent',
    requirePermission: true,
    roles: ['Super_Admin','Admin','User'],
}
];
